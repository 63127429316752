import React from 'react';
import SelectNumberRooms from './selectNumberRooms';
import PromotionalCode from './promotionalCode';
import SearchCriteriaFlights from './searchCriteriaFlights';
import FlightsDeparting from './flightsDeparting';

const FlightsElement = props => {
  const {
    withFlightsVisible,
    searchCriteria,
    handleSearchCriteriaChange,
    handlePromocodeChange,
    selectGateway,
    selectQuantityRooms,
    flightsElementClassName,
    searchCriteriaClassName,
    promocodeClassName,
    roomsClassName,
    withFlightsId,
  } = props;

  return (
    <>
      {!withFlightsVisible && (
        <>
          <FlightsDeparting
            flightsElementClassName={flightsElementClassName}
            selectGateway={selectGateway}
          />

          <SearchCriteriaFlights
            value={searchCriteria}
            onChange={handleSearchCriteriaChange}
            searchCriteriaClassName={searchCriteriaClassName}
          />
        </>
      )}

      <PromotionalCode
        promocodeClassName={promocodeClassName}
        onChange={handlePromocodeChange}
      />
      <SelectNumberRooms
        roomsClassName={roomsClassName}
        roomsId={withFlightsId}
        onChange={selectQuantityRooms}
      />
    </>
  );
};

export default FlightsElement;
