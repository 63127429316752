import React from 'react';
import PropTypes from 'prop-types';
import formActions from '~/js/components/global/form/utils/formActions';

const { errorHandler } = formActions;

function Submit({
  label,
  className,
  readyForSubmit,
  errors,
  isSubmitting,
  setFieldTouched,
}) {
  return (
    /* If no errors, formik will handle submission */
    readyForSubmit ? (
      <button
        type="submit"
        className={`${className} san-submit ${
          isSubmitting ? 'san-disabled' : ''
        }`}
      >
        {isSubmitting ? 'Processing...' : label}
      </button>
    ) : (
      <button
        onClick={() => errorHandler(errors, setFieldTouched)}
        type="button"
        className={`${className} san-submit`}
      >
        {label}
      </button>
    )
  );
}

Submit.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  readyForSubmit: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
};

Submit.defaultProps = {
  label: 'Submit',
  className: 'xs-4',
};

export default Submit;
