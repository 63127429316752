import React from 'react';
import { render } from 'react-dom';

import _ from '~/js/modules/utils';
import searchBar from '~/js/modules/includes/header-search-bar';
import QQ from '~/js/components/global/qq';

import GcvFormik from '~/js/components/general/accounts/GcvFormik.js';

const countryList = window.sandals_app.global.layout[0]?.resortsByCountry;

const accountsInformation =
  window.sandals_app.global.layout[0].miscellaneous[2].items;

const getResorts = () => {
  const result = [];

  countryList &&
    countryList.map(country =>
      country.resortsList.map(resort => {
        result.push({
          country: {
            name: country.countryShortName,
            code: country.countryCode,
          },
          shortName: resort.name,
          code: resort.code,
          url: resort.url,
        });
      })
    );

  return result;
};

const resorts = getResorts();

const header = () => {
  if (resorts.length) {
    let menuIsOpen = false;

    let isBookingOpen = false;

    let isAccountsOpen = false;

    let scrollPosition = window.scrollY;
    const bodyEl = document.querySelector('body');
    const mainEl = document.querySelector('.main-content');
    const mainElLinks = mainEl.querySelectorAll('a');
    const mainElButtons = mainEl.querySelectorAll('button');
    const isMobile = windowWidth => windowWidth < 768;
    const headerEl = document.getElementById('js-header');
    const backdrop = document.getElementById('js-hm__backdrop');
    const mobileToggle = document.getElementById('js-menu-btn');
    const menuEl = document.querySelector('.hm__menu');
    const headerLinks = headerEl.querySelectorAll('a');
    const headerButtons = headerEl.querySelectorAll('button');
    const focusableTags = [...headerLinks, ...headerButtons];
    const categoryLinks = headerEl.querySelectorAll('.categories__link');
    const subcategoryItems = headerEl.querySelectorAll('.categories__items');
    const backButton = headerEl.querySelectorAll('.back__btn');
    const searchLink = document.getElementById('search');
    const searchPanel = headerEl.querySelector('.hm__search');
    const searchCloseButton = headerEl.querySelector('.search__close');
    const searchInput = headerEl.querySelector('.search__bar');
    const searchReset = headerEl.querySelector('.search__reset');
    const bookingButton = headerEl.querySelector('.booking__btn');
    const skipContent = document.getElementById('js-skip__content');
    const outsideClick = document.getElementById('js-outside__click');
    const secondMenu = document.querySelector('.sticky-nav-wrapper');

    const accountsLogin = document.getElementById('accounts');
    const accountsPanel = headerEl.querySelector('.hm__accounts');
    const userProfile = document.getElementById('accounts-user');
    const userPanel = document.getElementById('js-accounts-user__content');

    // Close Categories
    const closeCategories = () => {
      Array.from(subcategoryItems).map(subcategoryItem =>
        subcategoryItem.classList.remove('categories__items--active')
      );
    };

    const removeActiveClass = className => {
      const activePanel = headerEl.querySelector(`.${className}`);
      const isActive = activePanel.classList.contains(`${className}--active`);

      isActive && activePanel.classList.remove(`${className}--active`);
    };

    // Close Booking
    const closeBooking = () => {
      if (isBookingOpen) {
        removeActiveClass('booking__menu');
        removeActiveClass('booking__btn');
        isBookingOpen = false;
      }
    };

    // Close Search
    const closeSearch = () => {
      const searchActive = searchPanel.classList.contains('hm__search--active');

      if (searchActive) {
        removeActiveClass('hm__search');
      }

      !isMobile(window.innerWidth) &&
        backdrop.classList.remove('hm__backdrop_search');
    };

    // Close Accounts
    const closeAccounts = () => {
      const searchActive = accountsPanel.classList.contains(
        'hm__accounts--active'
      );

      if (searchActive) {
        removeActiveClass('hm__accounts');
      }

      !isMobile(window.innerWidth) &&
        backdrop.classList.remove('hm__backdrop_accounts');
    };

    // Overlay
    const removeLinkOverlay = () => {
      [...categoryLinks].map(linkItem =>
        linkItem.classList.remove('categories__link--active')
      );
    };

    // Global classes
    const menuHandler = isOpen => {
      menuIsOpen = isOpen;

      if (isOpen) {
        headerEl.classList.add('header--open');
        outsideClick.classList.add('hm__outside');
        isMobile(window.innerWidth) && (bodyEl.style.overflow = 'hidden');
      } else {
        headerEl.classList.remove('header--open');
        outsideClick.classList.remove('hm__outside');
        isMobile(window.innerWidth) && (bodyEl.style.overflow = 'visible');
        closeCategories();
        closeBooking();
        closeSearch();
        closeAccounts();
        removeLinkOverlay();

        // if (!userProfile.classList.contains('hide-item')) {
        //   userProfile.click();
        // }
        if (menuEl.classList.contains('hm__menu--swiped')) {
          menuEl.classList.remove('hm__menu--swiped');
        }
      }
    };

    let lastScrollTop = 0;

    // Search & Booking Menus
    const handleActivePanel = className => {
      const activePanel = headerEl.querySelector(`.${className}`);
      const isActive = activePanel.classList.contains(`${className}--active`);

      if (isActive) {
        menuHandler(false);
        activePanel.classList.remove(`${className}--active`);
        isBookingOpen = false;
        isAccountsOpen = false;
      } else {
        menuHandler(true);
        activePanel.classList.add(`${className}--active`);
        isBookingOpen = true;
        isAccountsOpen = className === 'hm__accounts' ? true : false;
      }
    };

    // Scroll Header
    const scrollHeader = () => {
      const headerWrapper = document.querySelector('.hm__wrapper');
      const backdropWrapper = document.querySelector('.hm__backdrop');
      const scrollLimit =
        secondMenu !== null
          ? window.scrollY +
            document
              .querySelector('.sticky-nav-wrapper')
              .getBoundingClientRect().y
          : 99999;
      const accountActiveWrapper = document.querySelector(
        '.accounts__container'
      );

      scrollPosition = window.scrollY;

      if (scrollPosition > 10 && scrollPosition < scrollLimit) {
        headerWrapper.classList.add('hm__wrapper--active');
        backdropWrapper.classList.add('hm__backdrop--active');
        accountActiveWrapper.classList.add('scrolling');
        const isActive = headerWrapper.classList.contains(
          'hm__wrapper--active--hide'
        );

        isActive && headerWrapper.classList.remove('hm__wrapper--active--hide');
      } else if (scrollPosition >= scrollLimit) {
        headerWrapper.classList.add('hm__wrapper--active--hide');
      } else {
        removeActiveClass('hm__wrapper');
        removeActiveClass('hm__backdrop');
        accountActiveWrapper.classList.remove('scrolling');
        const isActive = headerWrapper.classList.contains(
          'hm__wrapper--active--hide'
        );

        isActive && headerWrapper.classList.remove('hm__wrapper--active--hide');
      }

      if (scrollPosition < lastScrollTop) {
        // upscroll code
        const isActive = headerWrapper.classList.contains(
          'hm__wrapper--active--hide'
        );

        isActive && headerWrapper.classList.remove('hm__wrapper--active--hide');
      }
      lastScrollTop = scrollPosition <= 0 ? 0 : scrollPosition;
    };

    // Active Category
    Array.from(categoryLinks).map(categoryItem => {
      const hrefHandler = e => {
        const href = e.target.getAttribute('href');

        if (href.trim().length < 1) {
          e.preventDefault();
        }
      };

      categoryItem.addEventListener('click', e => {
        e.preventDefault();
        hrefHandler(e);

        const activeCategory = headerEl.querySelector(
          `.categories__items_${categoryItem.dataset.id}`
        );

        const isActive = activeCategory.classList.contains(
          'categories__items--active'
        );

        closeCategories();
        closeAccounts();
        if (isActive) {
          menuHandler(false);
          activeCategory.classList.remove('categories__items--active');
          categoryItem.classList.remove('categories__link--active');
        } else {
          menuHandler(true);
          activeCategory.classList.add('categories__items--active');
          closeBooking();
          isMobile(window.innerWidth) &&
            menuEl.classList.add('hm__menu--swiped');

          // Handle overlay for links
          if (!isMobile(window.innerWidth)) {
            removeLinkOverlay();
            categoryItem.classList.add('categories__link--active');
          }

          const resortsItemLi = document.querySelectorAll(
            '.categories__items_resorts .items__list ul li'
          );

          for (const itemElem of resortsItemLi) {
            const elementA = itemElem.querySelector('a');
            const elementLabel = itemElem.querySelector('strong');

            if (elementLabel !== null) {
              const offsetRight = itemElem.offsetWidth - elementA.offsetWidth;

              elementLabel.style.right =
                offsetRight - elementLabel.offsetWidth - 5 + 'px';
            }
          }

          menuEl.classList.remove('main-nav-scrollable');
        }
      });
    });

    // Mobile
    const getMobileWindowHeight = () => {
      if (isMobile) {
        const vh = window.innerHeight * 0.01;

        document.documentElement.style.setProperty('--vh', `${vh}px`);
      }
    };

    mobileToggle.addEventListener('click', () => {
      if (menuIsOpen) {
        menuHandler(false);
        menuEl.classList.remove('hm__menu--active');
        closeBooking();
        closeSearch();
        closeAccounts();
        getMobileWindowHeight();
      } else {
        menuHandler(true);
        menuEl.classList.add('hm__menu--active');
      }
    });

    // Category back button
    [...backButton].map(button =>
      button.addEventListener('click', e => {
        e.preventDefault();

        [...subcategoryItems].find(item =>
          item.classList.remove('categories__items--active')
        );

        if (isMobile) {
          menuEl.classList.remove('hm__menu--swiped');
        }

        closeSearch();
        closeAccounts();
      })
    );

    // Accounts
    accountsLogin.addEventListener('click', e => {
      e.preventDefault();

      handleActivePanel('hm__accounts');
      isMobile(window.innerWidth) && menuEl.classList.add('hm__menu--swiped');

      if (!isMobile(window.innerWidth)) {
        closeCategories();
        closeBooking();
        removeLinkOverlay();
        backdrop.classList.add('hm__backdrop_accounts');
      }
    });

    userProfile.addEventListener('click', e => {
      e.preventDefault();

      menuEl.classList.toggle('main-nav-scrollable');

      userPanel.classList.toggle('show-profile');
      userPanel.classList.toggle('hide-profile');

      userProfile.classList.toggle('open-menu');

      if (!userProfile.classList.contains('hide-item')) {
        outsideClick.classList.add('hm__outside');
      }
    });

    // Search
    searchInput.addEventListener('focusin', e => {
      e.preventDefault();
    });

    searchLink.addEventListener('click', e => {
      e.preventDefault();

      handleActivePanel('hm__search');

      searchInput.focus();
      isMobile(window.innerWidth) && menuEl.classList.add('hm__menu--swiped');

      if (!isMobile(window.innerWidth)) {
        closeCategories();
        closeBooking();
        removeLinkOverlay();
        closeAccounts();
        backdrop.classList.add('hm__backdrop_search');
      }

      if (userProfile.classList.contains('open-menu')) {
        userProfile.click();
      }
    });

    searchInput.addEventListener('input', e => {
      e.target.value.length > 0
        ? searchReset.classList.add('search__reset--active')
        : searchReset.classList.remove('search__reset--active');
    });

    searchReset.addEventListener('click', () => {
      searchInput.value = '';
      searchReset.classList.remove('search__reset--active');
    });

    searchCloseButton.addEventListener('click', e => {
      e.preventDefault();

      handleActivePanel('hm__search');
      removeLinkOverlay();
      closeSearch();
      closeAccounts();
    });

    searchBar();

    // Booking
    bookingButton.addEventListener('click', e => {
      e.preventDefault();

      if (isMobile(window.innerWidth)) {
        const menuIsActive = menuEl.classList.contains('hm__menu--active');
        const searchActive =
          searchPanel.classList.contains('hm__search--active');

        menuIsActive && removeActiveClass('hm__menu');
        searchActive && removeActiveClass('hm__search');
      }

      handleActivePanel('booking__menu');
      closeCategories();
      !isMobile(window.innerWidth) && removeLinkOverlay();
      closeAccounts();
      // Handle Overlay
      if (isBookingOpen) {
        bookingButton.classList.add('booking__btn--active');
      } else {
        removeActiveClass('booking__btn');
      }
    });

    // A11y
    skipContent.addEventListener('keyup', e => {
      e.preventDefault();

      if (e.keyCode === 13) {
        mainElLinks[0]?.focus() || mainElButtons[0]?.focus();
      }
    });

    headerEl.addEventListener('keyup', e => {
      if (e.keyCode === 27 && menuIsOpen) {
        menuHandler(false);
      }
    });

    document.addEventListener('keyup', () => {
      const { activeElement } = document;
      const isMenuElement = focusableTags.includes(activeElement);
      const searchFocused = activeElement.classList.contains('search__bar');
      const openSearch = !!document.querySelector('.hm__backdrop_search');

      if (searchFocused || isAccountsOpen) {
        return;
      }

      if (!isMenuElement && menuIsOpen && !openSearch && !isBookingOpen) {
        menuHandler(false);
      }
    });

    // Resize
    const resizeScreen = _.debounce(() => {
      if (!isMobile(window.innerWidth)) {
        menuHandler(false);
      }
      getMobileWindowHeight();
    });

    // Click Ouside The Open Menu
    outsideClick.addEventListener('click', () => {
      menuHandler(false);

      if (userProfile.classList.contains('open-menu')) {
        userProfile.click();
      }
    });

    window.addEventListener('scroll', scrollHeader);
    window.addEventListener('resize', resizeScreen);
    window.addEventListener('touchmove', getMobileWindowHeight);

    // Accounts LogIn
    const accountsForm = document.getElementById('js-sign-in-accounts');

    if (accountsForm) {
      render(
        <GcvFormik accountsInformation={accountsInformation} />,
        accountsForm
      );
    }

    // Booking QQ //
    render(
      <QQ
        isCallendarUk
        resortSelect={{
          resortOptions: resorts,
          labelText: 'Select Resort / Destination',
        }}
        className="booking__qq"
        calendar={{
          iconCaret: false,
          iconCalendar: true,
          monthsToDisplay: 2,
          theme: 'dark',
          minimumNights: 1,
          alignment: 'center',
          checkinDateName: 'checkin-date',
          checkoutDateName: 'checkout-date',
          onApplyDates: () => {},
          onCloseCalendar: () => {},
          onClearDates: () => {},
        }}
        mainButton="Search"
        // bottomElement={<div>Book online and get $25 credit</div>}
        onChange={() => {}}
        horizontalSpecial
        horizontalSpecialAlignment
        noTabs
      />,
      document.getElementById('js-header__qq')
    );
  }
};

export default header;
