import React, { useState, useRef, useEffect } from 'react';
import flightsData from './flightsData.json';

const { gateways: rawGateways } = flightsData;

function escapeRegExp(str) {
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
}

const FlightsDeparting = props => {
  const { selectGateway, flightsElementClassName = 'xs-12' } = props;

  const [gateways, setGateways] = useState(rawGateways);
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [departureCity, setDepartureCity] = useState('');
  const flightsElementRef = useRef(null);
  const alertRef = useRef(null);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertShown, setAlertShown] = useState(false);

  const toggleAlert = () => {
    setOpenAlert(!openAlert);
  };

  const alert = () => {
    return (
      <>
        <div ref={alertRef} className="fl-custom-alert">
          <p className="fl-custom-alert-content">
            <span className="fl-custom-alert-head">Flying from London?</span>
            <br /> <br />
            Virgin Atlantic operate from London Heathrow.
            <br /> <br />
            British Airways operate from London Gatwick except for flights to
            Barbados and The Bahamas which operate from London Heathrow. <br />
            <br />
            For travel within the next 11 months, please search as above or
            select <strong>‘Any London’</strong>. <br /> <br />
            For travel outside of 11 months, please search using the specific
            airport outlined above. For Virgin Atlantic select London Heathrow.
            For British Airways select London Gatwick except Barbados/Bahamas,
            where you should select London Heathrow.
          </p>
          <button
            type="button"
            className="fl-alert-close"
            onClick={toggleAlert}
          >
            CLOSE
          </button>
        </div>
        <div className="alert-overlay" />
      </>
    );
  };

  const toggleOptionsVisible = () => {
    setOptionsVisible(!optionsVisible);
    /* if (!alertShown) {
      // Check if the alert has not been shown
      setOpenAlert(true);
      setAlertShown(true); // Update state to indicate the alert has been shown
    }*/
  };

  const closeOptions = () => {
    setOptionsVisible(false);
    // setOpenAlert(false);
  };

  const handleClick = e => {
    if (
      flightsElementRef.current &&
      !flightsElementRef.current.contains(e.target)
    ) {
      closeOptions();
    }
  };

  const clickGateway = e => {
    const value = e.currentTarget.getAttribute('data-value');
    const label = e.currentTarget.getAttribute('data-label');

    setDepartureCity(label);
    selectGateway({ value, label });
    closeOptions();
  };

  const handleDepartureCityChange = e => {
    const { currentTarget } = e;
    const { value } = currentTarget;
    const found = rawGateways.filter(g => {
      const label = `${g.city.toLocaleLowerCase()} ${g.stateName.toLocaleLowerCase()} ${g.state.toLocaleLowerCase()} ${g.country.toLocaleLowerCase()} ${g.gateway.toLocaleLowerCase()}`;

      return label.search(escapeRegExp(value.toLowerCase())) >= 0;
    });

    setDepartureCity(value);
    setGateways(found);
  };

  const registerMouseDown = () => {
    document.addEventListener('mousedown', handleClick);
  };

  const unRegisterMouseDown = () => {
    document.removeEventListener('mousedown', handleClick);
  };

  useEffect(() => {
    registerMouseDown();

    return () => {
      unRegisterMouseDown();
    };
  }, []);

  return (
    <div className={flightsElementClassName} ref={flightsElementRef}>
      {/* openAlert && alert()*/}
      <label htmlFor="departure-city">Flights departing from:</label>
      <input
        type="text"
        name="departure-city"
        id="departure-city"
        className="qq-input departure-city"
        value={departureCity}
        placeholder="Enter Airport or City"
        autoComplete="off"
        onChange={handleDepartureCityChange}
        onClick={toggleOptionsVisible}
      />
      {/* <i
        role="presentation"
        aria-label="icon"
        className="sandals-icons ic-marker"
      /> */}
      {optionsVisible && (
        <div className="row center-xs middle-xs departure-city-wrap">
          <div className="xs-12">
            <ul className="departure-city-list">
              {gateways.map((gateway, i) => {
                const dataLabel = `${gateway.airportName}`;
                const dataValue = `${gateway.gateway}`;

                return (
                  <li
                    key={i}
                    data-label={dataLabel}
                    data-value={dataValue}
                    onClick={clickGateway}
                  >
                    {dataLabel.toLocaleLowerCase()}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default FlightsDeparting;
