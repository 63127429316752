import React from 'react';
import { useField, Field } from 'formik';
import formActions from '~/js/components/global/form/utils/formActions';

const { addValidClass, compressor, charCounter } = formActions;

const TextInput = ({ unwrapped, children, label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);

  return unwrapped ? (
    <>
      <Field
        id={props.name}
        className="san-input input"
        {...field}
        {...props}
        required={false}
        aria-label={props.name}
        aria-describedby={`${props.name}-help`}
        placeholder={props.placeholder ? props.placeholder : ''}
      />

      {children}

      {meta.touched && meta.error ? (
        <span className="san-feedback">{meta.error}</span>
      ) : null}
    </>
  ) : (
    <div
      className={`row start-xs san-form-group ${
        meta.touched ? addValidClass(!meta.error) : ''
      }`}
    >
      <div className="xs-12">
        {label && (
          <label htmlFor={props.name} className="san-label">
            {label} {props.required && <sup>*</sup>}
          </label>
        )}

        {props.sublabel && (
          <span className="san-sublabel">{props.sublabel}</span>
        )}

        <Field
          id={props.name}
          className={`san-input${
            props.type === 'textarea' ? ' san-textarea' : ''
          } input`}
          {...field}
          {...props}
          as={props.type === 'textarea' ? 'textarea' : null}
          required={false}
          aria-label={props.name}
          aria-describedby={`${props.name}-help`}
        />
        {props.maxLength && (
          <span className="san-charcounter">
            {charCounter(field.value, props.maxLength)} characters left
          </span>
        )}

        {children}

        {meta.touched && meta.error ? (
          <span className="san-feedback">{meta.error}</span>
        ) : null}
      </div>
    </div>
  );
};

const Checkbox = ({ label, ...props }) => {
  // React treats radios and checkbox inputs differently other input types, select, and textarea.
  // Formik does this too! When you specify `type` to useField(), it will
  // return the correct bag of props for you -- a `checked` prop will be included
  // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
  const [field, meta] = useField({ ...props, type: 'checkbox' });

  return (
    <div
      className={`row start-xs san-form-group ${
        meta.touched ? addValidClass(!meta.error) : ''
      }`}
    >
      <div className="xs-12 san-inputgroup special-group">
        <Field
          id={props.name}
          type="checkbox"
          className="san-checkbox input"
          {...field}
          {...props}
          aria-label={props.name}
          aria-describedby={`${props.name}-help`}
          required={false}
        />

        <label htmlFor={props.name} className="san-label">
          {label} {props.required && <sup>*</sup>}
        </label>
      </div>
      <div className="xs-12 san-inputgroup special-group">
        {meta.touched && meta.error ? (
          <span className="san-feedback">{meta.error}</span>
        ) : null}
      </div>
    </div>
  );
};

const Radio = ({ label, value, ...props }) => {
  // React treats radios and checkbox inputs differently other input types, select, and textarea.
  // Formik does this too! When you specify `type` to useField(), it will
  // return the correct bag of props for you -- a `checked` prop will be included
  // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
  const [field, meta] = useField({ ...props, type: 'checkbox' });

  return (
    <div
      className={`row start-xs san-form-group ${
        meta.touched ? addValidClass(!meta.error) : ''
      }`}
    >
      <div className="xs-12 san-inputgroup special-group">
        <Field
          id={props.name}
          type="radio"
          className="san-radio input"
          {...field}
          {...props}
          value={value}
          required={false}
          aria-label={props.name}
          aria-describedby={`${props.name}-help`}
        />

        <label htmlFor={props.name} className="san-label">
          {label} {props.required && <sup>*</sup>}
        </label>
        {meta.touched && meta.error ? (
          <span className="san-feedback">{meta.error}</span>
        ) : null}
      </div>
    </div>
  );
};

const Select = ({ unwrapped, label, ...props }) => {
  const [field, meta] = useField(props);

  return unwrapped ? (
    <>
      <div className="select-wrapper san-input san-select">
        <Field
          id={props.name}
          as="select"
          className="select-element"
          {...field}
          {...props}
          required={false}
          aria-label={props.name}
          aria-describedby={`${props.name}-help`}
        />
      </div>

      {meta.touched && meta.error ? (
        <span className="san-feedback">{meta.error}</span>
      ) : null}
    </>
  ) : (
    <div
      className={`row start-xs san-form-group ${
        meta.touched ? addValidClass(!meta.error) : ''
      }`}
    >
      <div className="xs-12">
        <label htmlFor={props.name} className="san-label">
          {label} {props.required && <sup>*</sup>}
          {props.subheading && (
            <p className="san-label-subheading">{props.subheading}</p>
          )}
        </label>

        <div className="select-wrapper input title san-input san-select">
          <Field
            id={props.name}
            as="select"
            className="select-element"
            {...field}
            {...props}
            required={false}
            aria-label={props.name}
            aria-describedby={`${props.name}-help`}
          />
        </div>

        {meta.touched && meta.error ? (
          <span className="san-feedback">{meta.error}</span>
        ) : null}
      </div>
    </div>
  );
};

const InputGroup = ({ type, name, className, options }) => {
  return (
    <div
      className={`inputgroup-wrapper ${className} san-inputgroup`}
      role="group"
      aria-labelledby={`${type}-group`}
    >
      {options.map((option, i) => (
        <div
          key={`${i}-${type}-input`}
          className={`inputgroup-row inputgroup-row-${compressor(
            option.label
          )}`}
        >
          <Field
            className={`input input-${type}`}
            type={type}
            name={name}
            id={`${compressor(name)}-${compressor(option.value)}`}
            value={option.value}
            aria-label={name}
            aria-describedby={`${name}-help`}
          />

          <label
            htmlFor={`${compressor(name)}-${compressor(option.value)}`}
            className={`label-${compressor(option.label)}`}
          >
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
};

export { TextInput, Checkbox, Radio, Select, InputGroup };
