import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ResolveImg from '~/js/components/global/image/ResolveImg';

const Tabs = props => {
  const {
    className,
    noScroll,
    noContainer,
    labels = [],
    items = [],
    background,
    onChange,
    defaultTab = 0,
  } = props;

  const [activeTab, setActiveTab] = useState(defaultTab);

  const tabHandler = clickedTab => {
    setActiveTab(clickedTab);
    onChange && onChange(clickedTab);
  };

  return (
    <div className={`tabs ${className}__tabs`}>
      <div
        className={`tabs__wrapper${noScroll ? '' : ' tabs__wrapper--scroll'}`}
      >
        <div className={`tabs__inner ${noContainer ? '' : 'container'}`}>
          <ul className="tabs__ul">
            {labels.length > 0 &&
              labels.map((label, i) => {
                return (
                  <li
                    key={`li-${i}`}
                    className={`tabs__li${
                      activeTab === i ? ' tabs__li--active' : ''
                    }`}
                  >
                    <button
                      id={label.toLowerCase().replace(/\s/g, '')}
                      className="tabs__button"
                      onClick={() => tabHandler(i)}
                    >
                      <span className="tabs__title">{label}</span>
                    </button>
                  </li>
                );
              })}
          </ul>
        </div>

        <div className="tabs__background">
          {background && (
            <div className="progressive">
              <ResolveImg
                imgData={{ src: background, alt: 'Blurred background image' }}
              />
            </div>
          )}

          {items.length > 0 &&
            items.map((item, i) => {
              return (
                <div
                  key={`content-${i}`}
                  className={`tabs__content${
                    activeTab === i ? ' tabs__content--active' : ''
                  }`}
                >
                  {item}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

Tabs.propTypes = {
  labels: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  className: PropTypes.string,
  background: PropTypes.string,
  noContainer: PropTypes.bool,
  noScroll: PropTypes.bool,
};

Tabs.defaultProps = {
  className: 'default',
  noContainer: true,
  noScroll: false,
};

export default Tabs;
