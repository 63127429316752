/* eslint-disable */
const resolve = new com.liquidpixels.Resolve({
  server: 'resolve.sandals.com',
  uri: 'sri',
  port: '80',
  base: 'https://',
  imageFit: 'containerWidth',
  lazyLoad: true,
  advancedOptions: {
    cacheThreshold: 500
  },
  output: function(browser, image) {
    return {
      format: ['webp', image.format ? image.format : 'jpg']
    };
  }
});

export default resolve;
