import React from 'react';
import globalConfig from '../../../modules/global';
import Tabs from '~/js/components/global/tabs/TabsV2';
import SpecificDatesHorizontal from './specificDatesHorizontal';
// import FlexibleDatesHorizontal from './flexibleDatesHorizontal';

const HorizontalQQ = props => {
  const { ACTIVE_RATES } = globalConfig;
  const { tabsAlign = 'center', noTabs = false } = props;

  return (
    <div
      className={`horizontal-qq-wrapper tabs-align-${tabsAlign}${
        noTabs ? ' xs-12 no-tab-helper' : ''
      }`}
    >
      {!noTabs && (
        <Tabs
          className="qq"
          noScroll
          labels={ACTIVE_RATES ? ['Specific dates'] : ['Book Now']}
          items={[
            <SpecificDatesHorizontal
              key="1-tab"
              calendarWrapperClassName="horizontal-qq-calendar-wrap"
              withFlightsWrapperClassName="horizontal-qq-with-flights-wrap"
              {...props}
            />,
            // ACTIVE_RATES ? <FlexibleDatesHorizontal key="2-tab" /> : <></>,
          ]}
        />
      )}
      {noTabs && <SpecificDatesHorizontal {...props} />}
    </div>
  );
};

export default HorizontalQQ;
