import React, { useState, useEffect } from 'react';
import SliderWrapper from '~/js/components/global/slider-wrapper/SliderWrapper';

const SpecialsCarousel = props => {
  const { setCarouselItemId, visible } = props;
  const [visibleClass, setVisibleClass] = useState('hidden');

  const settings = {
    dots: false,
    initialSlide: 0,
    infinite: false,
    centerMode: true,
    className: 'xs-12 slide-wrap',
    variableWidth: true,
    afterChange: current => {
      setCarouselItemId(current);
    },
  };

  useEffect(() => {
    const isVisibleClass = props.visible ? 'uncollapsed' : 'collapsed';

    setVisibleClass(isVisibleClass);
  });

  return (
    <div className="row center-xs middle-xs qq-element-wrap">
      <div className="xs-12">
        <div
          className={`row center-xs middle-xs specials-carousel qq-element-transition qq-element-${visibleClass}`}
        >
          {visible && (
            <SliderWrapper settings={settings}>
              {props.specials.map((special, i) => {
                return <div key={i}>{special}</div>;
              })}
            </SliderWrapper>
          )}
        </div>
      </div>
    </div>
  );
};

export default SpecialsCarousel;
