import React from 'react';

const DiscountOffers = props => {
  const { leftText, percentage, rightText, linkUrl, linkText, icon } = props;

  return (
    <div className="row center-xs bottom-xs qq-discount-offers">
      <div className="xs-12">
        <span
          dangerouslySetInnerHTML={{
            __html: `${leftText} ${percentage}% ${rightText}`,
          }}
        />
      </div>
      <div className="xs-12">
        <a href={linkUrl} className="discount-link">
          {linkText} {icon}
        </a>
      </div>
    </div>
  );
};

export default DiscountOffers;
