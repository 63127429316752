import m from 'moment';

const todaysDate = m();
const globalConfig = {};
const { env } = window.sandals_app;

globalConfig.ACTIVE_RATES = false;
globalConfig.DEFAULTS = {
  minimumNights: 1,
  minDate: m(todaysDate, 'MM-DD').add(1, 'days'),
  maxDate: m('2027-01-01', 'YYYY-MM-DD'),
  minDateSSV: m(todaysDate, 'MM-DD-YYYY', true),
  maximumNights: 21,
  children: 0,
  infants: 0,
  ssgNumber: 0,
  seatType: '',
  promocode: '',
  adults: 2,
  rooms: 1,
  airIncluded: false,
  resortCode: sessionStorage.getItem('generalRstCode'),
  obeURL:
    env === 'development'
      ? 'https://booking.sandals.co.uk/nx/start.pl'
      : env === 'STAGING'
      ? 'https://booking.sandals.co.uk/nx/start.pl'
      : 'https://booking.sandals.co.uk/nx/start.pl',
};

export default globalConfig;
