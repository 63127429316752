import React, { useState, useEffect } from 'react';

import ResortSelect from '../resortSelect';
import Calendar from '../calendar';
import MainButton from '../mainButton';
import globalConfig from '../../../../modules/global';
import SelectNumberRooms from '../selectNumberRooms';
import PromotionalCode from '../promotionalCode';
import SearchCriteriaFlights from '../searchCriteriaFlights';
import FlightsDeparting from '../flightsDeparting';
import WithFlightsElementV2 from '../withFlightsElementV2';

const SpecificDatesHorizontalRegularV2 = props => {
  const { DEFAULTS } = globalConfig;
  const {
    mainButton = null,
    buttonAction = null,
    targetBlank = false,
    customCallback = null,
    onInputClick = null,
    bottomElement = null,
    calendar = {
      defaultText: '',
      iconCaret: false,
      iconCalendar: false,
      monthsToDisplay: 0,
      theme: 'light',
      checkinDateName: '',
      checkoutDateName: '',
      minDate: DEFAULTS.minDate,
      maxDate: DEFAULTS.maxDate,
      minimumNights: 1,
      onApplyDates: () => {},
      onCloseCalendar: () => {},
      onClearDates: () => {},
      showPricePerDay: false,
    },
    resortSelect = {
      resortOptions: [],
    },
    saveVacationData,
    vacationData,
    resetQQ = false,
    withFlightsId,
    calendarWrapperClassName = '',
    withFlightsWrapperClassName = '',
  } = props;

  const { resortOptions } = resortSelect;
  const {
    defaultText,
    iconCaret,
    iconCalendar,
    saveInputDate,
    monthsToDisplay,
    theme: calendarTheme,
    checkinDateName,
    checkoutDateName,
    minDate,
    maxDate,
    minimumNights,
    onApplyDates,
    onClearDates,
    onCloseCalendar,
    showPricePerDay,
  } = calendar;

  const [withFlightsVisible, setWithFlightsVisible] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState('');
  // const [size, setSize] = useState([0, 0]);

  useEffect(() => {
    const checkInDate = sessionStorage.getItem('startDate');
    const checkOutDate = sessionStorage.getItem('endDate');

    saveVacationData({ checkInDate, checkOutDate, withFlightsVisible });
  }, []);

  const toggleWithFlights = () => {
    const seatType = '';

    setWithFlightsVisible(!withFlightsVisible);
    saveVacationData({
      gateway: '',
      seatType,
      withFlightsVisible: !withFlightsVisible,
    });
  };

  const handleSearchCriteriaChange = e => {
    const { currentTarget } = e;
    const { value: seatType } = currentTarget;

    setSearchCriteria(seatType);
    saveVacationData({ seatType });
  };

  const handlePromocodeChange = e => {
    const { currentTarget } = e;
    const { value: promocode } = currentTarget;

    saveVacationData({ promocode });
  };

  const selectGateway = ({ value: gateway }) => {
    saveVacationData({ gateway });
  };

  const selectQuantityRooms = rooms => {
    saveVacationData({ rooms });
  };

  useEffect(() => {
    if (resetQQ) {
      setWithFlightsVisible(false);
      setSearchCriteria('');

      saveVacationData({
        rstCode: '',
        checkInDate: '',
        checkOutDate: '',
        rooms: '1',
        gateway: '',
        seatType: '',
        promocode: '',
        withFlightsVisible: false,
      });
    }
  }, [resetQQ]);

  return (
    <div className="row center-xs" style={{ paddingTop: '1.5rem' }}>
      {!withFlightsVisible && (
        <div>
          <div className="mainNewBookingWidget">
            <div className="selectResort xs-12 sm-4 md-4 lg-4 xl-3 flexBasisCorrection">
              <ResortSelect
                alignment="horizontal"
                resortOptions={resortOptions}
                vacationData={vacationData}
                saveVacationData={saveVacationData}
                onInputClick={onInputClick}
                resetInput={resetQQ}
              />
            </div>
            <FlightsDeparting
              flightsElementClassName="selectAirport xs-12 sm-4 md-4 lg-4 xl-4 flights-departing flexBasisCorrection"
              selectGateway={selectGateway}
            />
            <SearchCriteriaFlights
              value={searchCriteria}
              onChange={handleSearchCriteriaChange}
              searchCriteriaClassName="selectClass xs-12 sm-4 md-4 lg-4 xl-4 qq-resort-select flexBasisCorrection"
            />
            <div className="selectCalendar xs-12 sm-7 md-7 xl-4 flexBasisCorrection">
              <Calendar
                defaultText={defaultText}
                iconCaret={iconCaret}
                iconCalendar={iconCalendar}
                saveInputDate={saveInputDate}
                theme={calendarTheme}
                checkinDateName={checkinDateName}
                checkoutDateName={checkoutDateName}
                minDate={minDate}
                maxDate={maxDate}
                minimumNights={minimumNights}
                monthsToDisplay={monthsToDisplay}
                vacationData={vacationData}
                saveVacationData={saveVacationData}
                onInputClick={onInputClick}
                alignment="float"
                onApplyDates={onApplyDates}
                onClearDates={onClearDates}
                onCloseCalendar={onCloseCalendar}
                resetInput={resetQQ}
                resortOptions={resortOptions}
                showPricePerDay={
                  showPricePerDay ? !withFlightsVisible : showPricePerDay
                }
                showNightsLimit
              />
            </div>
            <WithFlightsElementV2
              id={withFlightsId[0]}
              WithFlightsElementClassName="selectFlightElement xs-12 sm-5 md-5 lg-4 xl-3"
              value={withFlightsVisible}
              onClick={toggleWithFlights}
            />
            <div className="selectPromoRoom xs-12 xl-6 md-10  flexBasisCorrection">
              <PromotionalCode
                promocodeClassName="xs-8 sm-4 md-5 xl-4"
                onChange={handlePromocodeChange}
              />
              <SelectNumberRooms
                roomsClassName="xs-5 sm-3 md-3 xl-3"
                roomsId={withFlightsId[1]}
                onChange={selectQuantityRooms}
              />
            </div>
          </div>
          <div className="buttonDivision">
            <MainButton
              additionalClassName="SearchButtonIndx"
              copy={mainButton}
              bottomElement={bottomElement}
              vacationData={vacationData}
              buttonAction={buttonAction}
              targetBlank={targetBlank}
              customCallback={customCallback}
              horizontal
            />
          </div>
        </div>
      )}

      {withFlightsVisible && (
        <div>
          <div className="mainNewBookingWidget">
            <div className="selectResort-WFV xs-12 sm-4 md-4 lg-4 xl-3 flexBasisCorrection">
              <ResortSelect
                alignment="horizontal"
                resortOptions={resortOptions}
                vacationData={vacationData}
                saveVacationData={saveVacationData}
                onInputClick={onInputClick}
                resetInput={resetQQ}
              />
            </div>
            <div className="selectCalendar-WFV xs-12 sm-7 md-7 xl-4 flexBasisCorrection">
              <Calendar
                defaultText={defaultText}
                iconCaret={iconCaret}
                iconCalendar={iconCalendar}
                saveInputDate={saveInputDate}
                theme={calendarTheme}
                checkinDateName={checkinDateName}
                checkoutDateName={checkoutDateName}
                minDate={minDate}
                maxDate={maxDate}
                minimumNights={minimumNights}
                monthsToDisplay={monthsToDisplay}
                vacationData={vacationData}
                saveVacationData={saveVacationData}
                onInputClick={onInputClick}
                alignment="float"
                onApplyDates={onApplyDates}
                onClearDates={onClearDates}
                onCloseCalendar={onCloseCalendar}
                resetInput={resetQQ}
                resortOptions={resortOptions}
                showPricePerDay={
                  showPricePerDay ? !withFlightsVisible : showPricePerDay
                }
                showNightsLimit
              />
            </div>
            <WithFlightsElementV2
              id={withFlightsId[0]}
              WithFlightsElementClassName="selectFlightElement-WFV xs-12 sm-5 md-5 lg-4 xl-3"
              value={withFlightsVisible}
              onClick={toggleWithFlights}
            />
            <div className="selectPromoRoom-WFV xs-12 xl-6 md-10  flexBasisCorrection">
              <PromotionalCode
                promocodeClassName="xs-8 sm-4 md-5 xl-4"
                onChange={handlePromocodeChange}
              />
              <SelectNumberRooms
                roomsClassName="xs-6 sm-3 md-3 xl-3"
                roomsId={withFlightsId[1]}
                onChange={selectQuantityRooms}
              />
            </div>
          </div>
          <div className="buttonDivision">
            <MainButton
              copy={mainButton}
              additionalClassName="SearchButtonIndx-WFV"
              bottomElement={bottomElement}
              vacationData={vacationData}
              buttonAction={buttonAction}
              targetBlank={targetBlank}
              customCallback={customCallback}
              horizontal
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SpecificDatesHorizontalRegularV2;
