import React from 'react';

const BottomElement = props => {
  const { children } = props;

  return (
    <div className="row center-xs middle-xs qq-element-wrap">
      <div className="xs-12">
        <div className="row center-xs middle-xs qq-bottom-element">
          <div className="xs-12">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default BottomElement;
