import React, { useRef } from 'react';
import PropTypes from 'prop-types';

const defaultFallback =
  'https://cdn.sandals.com/sandals/v13/images/EN/resorts/global/coming-soon/background.jpg';

const ResolveImg = ({
  imgData: {
    className,
    src,
    alt,
    altImg,
    fetchPriority = 'auto',
    loading = 'lazy',
    fallback = defaultFallback,
    onLoad,
  },
}) => {
  const placeHolder = altImg ? altImg : defaultFallback;
  const imgRef = useRef();
  const hasSrc = typeof src !== 'undefined';
  const httpsSrc = hasSrc
    ? src.includes('https://') || src.includes('http://')
      ? src
      : src.replace('//', 'https://')
    : placeHolder;
  // CDN 2
  const httpsScr2 = httpsSrc.includes('cdn2')
    ? httpsSrc
    : httpsSrc.replace('cdn', 'cdn2');

  const isSvg = hasSrc && src.includes('.svg');
  const isGif = hasSrc && src.includes('.gif');

  return isSvg || isGif ? (
    <img
      ref={imgRef}
      fetchpriority={fetchPriority}
      loading={loading}
      src={httpsSrc}
      className={`${className ? `${className}__img` : ''}`}
      alt={alt}
      onLoad={onLoad}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = fallback;
      }}
    />
  ) : (
    <img
      ref={imgRef}
      fetchpriority={fetchPriority}
      loading={loading}
      src={httpsSrc}
      className={`fullFormatImg${className ? `${className}__img` : ''}`}
      alt={alt}
      onLoad={onLoad}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = fallback;
      }}
    />
  );
};

ResolveImg.propTypes = {
  imgData: PropTypes.shape({
    className: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
    altImg: PropTypes.string,
    fetchPriority: PropTypes.oneOf(['high', 'low', 'auto']),
    loading: PropTypes.oneOf(['eager', 'lazy']),
    /** Specifies whether the image should be rerendered.
     * @deprecated Not applicable to native image tag.
     */
    forceUpdate: PropTypes.bool,
  }),
};

export default ResolveImg;
