import * as Yup from 'yup';
import formActions from '~/js/components/global/form/utils/formActions';
const { minChar, validateZip, validateMob, validateFileType, errorMsg } =
  formActions;

const phoneYup = areaCodeName => {
  const defaultPhoneYup = Yup.string()
    .required(errorMsg('required'))
    .test('validateMob', errorMsg('validateMob'), phoneVal =>
      validateMob(phoneVal)
    )
    .test('validateMinChar', errorMsg('minChar'), phoneVal =>
      minChar(phoneVal)
    );

  return Yup.string().when(areaCodeName, areaCode => {
    if (areaCode) {
      return defaultPhoneYup;
    }

    return defaultPhoneYup.test(
      'validateAreaCode',
      errorMsg('validateAreaCode'),
      () => false
    );
  });
};
const guestPhoneYup = areaCodeName => {
  const defaultPhoneYup = Yup.string()
    .required(errorMsg('required'))
    .test('validateMob', errorMsg('validateMob'), phoneVal =>
      validateMob(phoneVal)
    )
    .test('validateMinChar', errorMsg('minChar'), phoneVal =>
      minChar(phoneVal)
    );

  return Yup.string().when(areaCodeName, guestAreaCode => {
    if (guestAreaCode) {
      return defaultPhoneYup;
    }

    return defaultPhoneYup.test(
      'validateAreaCode',
      errorMsg('validateAreaCode'),
      () => false
    );
  });
};

const confirmEmailYup = emailName => {
  return Yup.string().when(emailName, email => {
    if (email) {
      return Yup.string().test(
        'compareEmails',
        errorMsg('compareEmails'),
        confirmEmail => email === confirmEmail
      );
    }

    return Yup.string().required(errorMsg('required'));
  });
};

const zipCodeYup = countryName => {
  return Yup.string().when(countryName, country => {
    if (country) {
      return Yup.string()
        .required(errorMsg('required'))
        .test('validateZip', errorMsg('validateZip'), zipCode =>
          validateZip(country, zipCode)
        );
    }

    return Yup.string().required(errorMsg('required'));
  });
};

const dateMatch = (startDateName, endDateName) => {
  return Yup.date().when(startDateName, (startDate, schema) => {
    if (startDate) {
      return schema.test('validateDate', errorMsg('validateDate'), endDate => {
        if (endDate) {
          return new Date(endDate) >= new Date(startDate);
        }

        return false;
      });
    }

    return schema.required(errorMsg('required'));
  });
};

const fileUploadYup = (fileTypes, maxSize) => {
  return Yup.mixed()
    .required(errorMsg('required'))
    .test('validateFileType', errorMsg('validateFileType'), value => {
      if (value) {
        return validateFileType(value, fileTypes);
      }

      return () => false;
    })
    .test('maxFileSize', errorMsg('maxFileSize'), value => {
      if (value) {
        return value?.size <= maxSize;
      }

      return () => false;
    });
};

const yupValidations = {
  string: {
    required: Yup.string().required(errorMsg('required')),
  },
  boolean: {
    required: Yup.bool().oneOf([true], errorMsg('required')),
  },
  recaptcha: Yup.string().nullable().required(errorMsg('required')),
  email: Yup.string()
    .email('Please enter a valid email address.')
    .required(errorMsg('required')),
  confirmEmail: confirmEmailYup,
  zipCode: zipCodeYup,
  phoneNumber: phoneYup,
  guestPhoneNumber: guestPhoneYup,
  file: fileUploadYup,
  array: {
    required: Yup.array()
      .min(1, 'Tick at least one box')
      .required(errorMsg('required')),
  },
  dateMatch,
};

export default yupValidations;
