import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import usePrevious from '~/js/modules/hooks/usePrevious';
import resolve from '~/js/modules/third-party/resolve';

const ResolveImg = ({ imgData, forceUpdate }) => {
  const [active, setActive] = useState(forceUpdate ? false : true);
  const { className, src, alt, altImg } = imgData;

  const placeHolder = altImg
    ? altImg
    : 'https://cdn.sandals.com/sandals/v13/images/EN/resorts/global/coming-soon/background.jpg';
  const imgRef = useRef();
  const hasSrc = typeof src !== 'undefined';
  const httpsSrc = hasSrc
    ? src.includes('https://') || src.includes('http://')
      ? src
      : src.replace('//', 'https://')
    : placeHolder;
  const prevSrc = usePrevious(src);
  const srcChanged = prevSrc !== src;
  const isSvg = hasSrc && src.includes('.svg');
  // After resolveImg .gif has a larger size and compression artifact
  const isGif = hasSrc && src.includes('.gif');

  useEffect(() => {
    let mounted = true;

    let updateTimeout;
    const updateImage = () => {
      resolve.updateImage(imgRef.current, false);
    };

    const forceUpdateImage = () => {
      updateTimeout = setTimeout(() => {
        setActive(true);
        updateImage();
      }, 100);
    };

    if (mounted && !isSvg && !isGif) {
      if (forceUpdate || srcChanged) {
        setActive(false);
        forceUpdateImage();
      } else {
        updateImage();
      }
    }

    return () => {
      mounted = false;
      clearTimeout(updateTimeout);
    };
  }, [src]);

  return (
    active &&
    (isSvg || isGif ? (
      <img
        ref={imgRef}
        src={httpsSrc}
        className={`${className ? `${className}__img` : ''}`}
        alt={alt}
      />
    ) : (
      <img
        ref={imgRef}
        src="https://resolve.sandals.com/sri?blank=height[1],width[1],color[none]&sink=format[gif]"
        data-resolvechain={`set=fileName[${httpsSrc}]&source=url[global.fileName],name[image],continueonerror[true]&set=prdImageFound[global.source.success]&source=url[${placeHolder}],name[base1],if[(! global.source.success)]&sink=if[(global.prdImageFound)]&sink=nocache[true],if[(! global.prdImageFound)]`}
        className={`Resolve${className ? ` ${className}__img` : ''}`}
        alt={alt}
      />
    ))
  );
};

ResolveImg.propTypes = {
  imgData: PropTypes.shape({
    className: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
    altImg: PropTypes.string,
    forceUpdate: PropTypes.bool,
  }),
};

export default ResolveImg;
