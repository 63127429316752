const regexPatterns = {
  numeric: /\D/g,
  zip: {
    ALL: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
    US: /(^$)|(^[0-9]{5}$)|(^[0-9]{5}-[0-9]{4}$)/,
    CA: /(^$)|(^[\w\d]{3} [\w\d]{3}$)|(^[\w\d]{6}$)/,
    GB: /^(([A-Z]{1,2}\d[A-Z\d]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?\d[A-Z]{2}|BFPO ?\d{1,4}|(KY\d|MSR|VG|AI)[ -]?\d{4}|[A-Z]{2} ?\d{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/i,
  },
  phone: {
    ALL: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
  },
};

const yesNoOptions = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

/* eslint-disable */
const postForm = async (url, params) => {
  /* eslint-enable */
  const resp = { code: 0, message: '', content: {} };

  if (params.gcaptcha || params.recaptcha) {
    params['g-recaptcha-response'] = params.gcaptcha || params.recaptcha;
    params['google-recaptcha'] = true;
  }

  try {
    const fetchResp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const { status, ok } = fetchResp;

    const respJson = await fetchResp.json();
    const { status: statusJson } = respJson;

    if (status === 200 && ok && statusJson === 'success') {
      resp.code = 1;
      resp.content = respJson.data;
    } else {
      resp.message = respJson.message.split('_').join(' ').toLowerCase();
      resp.message =
        resp.message.charAt(0).toUpperCase() + resp.message.slice(1) + '.';
    }
  } catch (error) {
    resp.message = error.message;
  }

  return resp;
};

const postFormData = async (url, params) => {
  const resp = { code: 0, message: '', content: {} };
  const formData = new FormData();

  if (params.gcaptcha || params.recaptcha) {
    formData.append(
      'g-recaptcha-response',
      params.gcaptcha || params.recaptcha
    );
    formData.append('google-recaptcha', true);
  }

  for (const [key, value] of Object.entries(params)) {
    formData.append(key, value);
  }

  try {
    const fetchResp = await fetch(url, {
      method: 'POST',
      body: formData,
      headers: {
        Accept: '*/*',
      },
    });

    const { status, ok } = fetchResp;

    const respJson = await fetchResp.json();
    const { status: statusJson } = respJson;

    if (status === 200 && ok && statusJson === 'success') {
      resp.code = 1;
      resp.content = respJson.data;
    } else {
      resp.message = respJson.message.split('_').join(' ').toLowerCase();
      resp.message =
        resp.message.charAt(0).toUpperCase() + resp.message.slice(1) + '.';
    }
  } catch (error) {
    resp.message = error.message;
  }

  return resp;
};

function scrollToError(errors, customOffset = 130) {
  const keysArray = Object.keys(errors);

  for (let i = 0; i < keysArray.length; i++) {
    const keyName = keysArray[i];
    const keyValue = errors[keyName];

    if (keyValue) {
      const input =
        document.querySelectorAll(`[aria-label="${keyName}"]`)[0] || {};

      const y =
        input.getBoundingClientRect().top +
        window.pageYOffset +
        customOffset * -1;

      window.scrollTo({ top: y });

      break;
    }
  }
}

const errorChecker = (errors = {}, gcvErrors = {}) => {
  const formOk = !Object.keys(errors).length > 0;
  const gcvOk = Object.keys(gcvErrors).every(error => !gcvErrors[error]);
  const passedValidation = formOk && gcvOk;

  return passedValidation;
};

const errorHandler = (errors = {}, showError = () => {}) => {
  const errorNames = Object.keys(errors);

  for (let i = 0; i < errorNames.length; i++) {
    showError(errorNames[i], true, false);
  }

  scrollToError(errors);
};

function scrollToMessage(customOffset = 130) {
  const messageBox =
    document.querySelectorAll('[aria-label="important-dialog"]')[0] || {};

  const y =
    messageBox.getBoundingClientRect().top +
    window.pageYOffset +
    customOffset * -1;

  window.scrollTo({ top: y });
}

function addValidClass(validity) {
  let ret = '';

  if (typeof validity === 'boolean') {
    ret = validity ? 'is-valid' : 'is-invalid';
  } else {
    ret = 'is-pristine';
  }

  return ret;
}

// chars left counter for textarea
const charCounter = (value, maxChar) => {
  const textLength = value?.length || 0;

  if (textLength <= maxChar) {
    return maxChar - textLength;
  }

  return 0;
};

// extra validation
const errorMsg = (
  validationName,
  minCharacters = 8,
  customErrorMsg,
  maxFileSize = 3
) => {
  switch (validationName) {
    case 'required':
      return 'This field is required.';
    case 'validateMob':
      return 'Please enter a valid phone number.';
    case 'validateZip':
      return 'Please enter a valid zip code.';
    case 'validateAreaCode':
      return 'Please select an area code';
    case 'minChar':
      return `Please enter at least ${minCharacters} characters.`;
    case 'validateFileType':
      return 'Please choose file in correct format';
    case 'maxFileSize':
      return `File must be less than ${maxFileSize} MB`;
    case 'email':
      return 'Please enter a valid email address.';
    case 'compareEmails':
      return 'Emails do not match.';
    case 'errorResponse':
      return customErrorMsg;
    case 'validateDate':
      return "Date can't be before arrival";
    default:
      return 'This field is required';
  }
};

const minChar = (value, maxLength = 8) => {
  let maxCharacters = maxLength;
  const stringValue = String(value).replace(/\s/g, '');

  if (stringValue.includes('+')) {
    maxCharacters += 1;
  }

  const splitValue =
    stringValue !== '' && stringValue !== null ? stringValue.split('') : [];

  if (splitValue.length < maxCharacters) {
    return false;
  }

  return true;
};

const regexTest = (value, regex) => {
  return regex.test(value);
};

const validateZip = (countryVal, zipVal) => {
  const { zip } = regexPatterns;
  const specialCountry =
    countryVal === 'US' || countryVal === 'CA' || countryVal === 'GB';

  const regexPat = specialCountry ? zip[countryVal] : zip.ALL;

  return regexTest(zipVal, regexPat);
};

const validateMob = phoneVal => {
  const { phone } = regexPatterns;

  return regexTest(phoneVal, phone.ALL);
};

const validateFileType = (value, fileTypeArray) => {
  const splitFileName = value?.name ? value.name.split('.') : ['null'];
  const fileType = splitFileName[splitFileName.length - 1];

  const result = fileTypeArray.some(
    type => type.toLowerCase() === fileType.toLowerCase()
  );

  if (result) {
    return true;
  }

  return false;
};

const sortCountries = countries => {
  const defaultCountries = [];
  const specialCountries = {};

  for (let i = 0; i < countries.length; i++) {
    if (
      countries[i].isoCode === 'US' ||
      countries[i].isoCode === 'CA' ||
      countries[i].isoCode === 'GB'
    ) {
      specialCountries[countries[i].isoCode] = { ...countries[i] };
    } else {
      defaultCountries.push(countries[i]);
    }
  }

  const cleanAreaCodes = defaultCountries.filter(
    areaCode => !areaCode.phonecode.includes('and')
  );

  return [
    specialCountries.US,
    specialCountries.CA,
    specialCountries.GB,
    ...cleanAreaCodes,
  ];
};

const sanitizeValues = dirtyValues => {
  const resp = {};
  const keysArray = Object.keys(dirtyValues);

  for (let i = 0; i < keysArray.length; i++) {
    let cleanValue;

    const keyName = keysArray[i];
    const keyValue = dirtyValues[keyName];

    if (typeof keyValue !== 'object') {
      cleanValue = JSON.parse(JSON.stringify({ [keyName]: keyValue }));
    }

    if (Array.isArray(keyValue)) {
      cleanValue = { [keyName]: keyValue.join(',') };
    } else if (keyValue === 'true' || keyValue === 'false') {
      cleanValue = { [keyName]: keyValue.toLowerCase() === 'true' };
    } else {
      cleanValue = { [keyName]: keyValue };
    }

    Object.assign(resp, cleanValue);
  }

  return resp;
};

const formatPhone = (areaCode, phoneNumber) => {
  const areaStr = areaCode.replace(regexPatterns.numeric, '');
  const phoneStr = areaStr.concat(phoneNumber);

  return phoneStr;
};

const isWhiteSpace = char => {
  return ' \t\n'.includes(char);
};

const isPunct = char => {
  return ';:.,?!-\'"(){}'.includes(char);
};

const compressor = string => {
  return string
    .split('')
    .filter(char => !isWhiteSpace(char) && !isPunct(char))
    .join('')
    .toLowerCase();
};

const createSelect = (countStart, countEnd) => {
  const selectArray = [];

  for (let i = countStart; i <= countEnd; i++) {
    selectArray.push(i);
  }

  return selectArray.map(item => {
    return {
      label: `${item}`,
      value: `${item}`,
    };
  });
};

export const FormActions = {
  post: postForm,
  postFormData: postFormData,
  errorChecker,
  errorHandler,
  scrollToError,
  addValidClass,
  scrollToMessage,
  charCounter,
  regexPatterns,
  regexTest,
  validateZip,
  validateMob,
  minChar,
  validateFileType,
  errorMsg,
  sortCountries,
  formatPhone,
  compressor,
  yesNoOptions,
  createSelect,
  sanitizeValues,
};

export default FormActions;
