import React, { useState, useEffect } from 'react';

import ResortSelect from '../resortSelect';
import Calendar from '../calendar';
import MainButton from '../mainButton';
import WithFlightsElement from '../withFlightsElement';
import FlightsElement from '../flightsElement';
import globalConfig from '../../../../modules/global';
import FlightsDeparting from '../flightsDeparting';
import SearchCriteriaFlights from '../searchCriteriaFlights';
import SelectNumberRooms from '../selectNumberRooms';
import PromotionalCode from '../promotionalCode';
import WithFlightsElementV2 from '../withFlightsElementV2';

const SpecificDatesHorizontalSpecialV2 = props => {
  const { DEFAULTS } = globalConfig;

  const {
    mainButton = null,
    buttonAction = null,
    targetBlank = false,
    customCallback = null,
    onInputClick = null,
    bottomElement = null,
    calendar = {
      defaultText: '',
      iconCaret: false,
      iconCalendar: false,
      monthsToDisplay: 0,
      theme: 'light',
      checkinDateName: '',
      checkoutDateName: '',
      minDate: DEFAULTS.minDate,
      maxDate: DEFAULTS.maxDate,
      minimumNights: 1,
      onApplyDates: () => {},
      onCloseCalendar: () => {},
      onClearDates: () => {},
      showPricePerDay: false,
    },
    resortSelect = {
      resortOptions: [],
      labelText: '',
    },
    saveVacationData,
    vacationData,
    resetQQ = false,
    horizontalSpecialAlignment = false,
    withFlightsId,
    calendarWrapperClassName = '',
    withFlightsWrapperClassName = '',
  } = props;

  const { resortOptions, labelText } = resortSelect;
  const {
    defaultText,
    iconCaret,
    iconCalendar,
    saveInputDate,
    monthsToDisplay,
    theme: calendarTheme,
    checkinDateName,
    checkoutDateName,
    minDate,
    maxDate,
    minimumNights,
    onApplyDates,
    onClearDates,
    onCloseCalendar,
    showPricePerDay = true,
  } = calendar;

  // console.log('checkoutDateName', checkoutDateName);
  // console.log('checkinDateName', checkinDateName);

  const [withFlightsVisible, setWithFlightsVisible] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState('');
  // const [size, setSize] = useState([0, 0]);

  const toggleWithFlights = () => {
    const seatType = '';

    setWithFlightsVisible(!withFlightsVisible);
    saveVacationData({
      gateway: '',
      seatType,
      withFlightsVisible: !withFlightsVisible,
    });
  };

  const handleSearchCriteriaChange = e => {
    const { currentTarget } = e;
    const { value: seatType } = currentTarget;

    setSearchCriteria(seatType);
    saveVacationData({ seatType });
  };

  const handlePromocodeChange = e => {
    const { currentTarget } = e;
    const { value: promocode } = currentTarget;

    saveVacationData({ promocode });
  };

  const selectGateway = ({ value: gateway }) => {
    saveVacationData({ gateway });
  };

  const selectQuantityRooms = rooms => {
    saveVacationData({ rooms });
  };

  useEffect(() => {
    if (resetQQ) {
      setWithFlightsVisible(false);
      setSearchCriteria('');

      saveVacationData({
        rstCode: '',
        checkInDate: '',
        checkOutDate: '',
        rooms: '1',
        gateway: '',
        seatType: '',
        promocode: '',
        withFlightsVisible: false,
      });
    }
  }, [resetQQ]);

  return (
    <>
      {!withFlightsVisible && (
        <div className="center-lg">
          <div className="row special-horizontal-qq">
            <div className="selectSpecialResort xs-12 sm-4 md-4 lg-4 xl-3">
              <ResortSelect
                alignment="horizontal"
                resortOptions={resortOptions}
                vacationData={vacationData}
                saveVacationData={saveVacationData}
                onInputClick={onInputClick}
                resetInput={resetQQ}
              />
            </div>
            <FlightsDeparting
              flightsElementClassName="selectAirportSpecial xs-12 sm-4 md-4 lg-4 xl-4 flights-departing"
              selectGateway={selectGateway}
            />
            <SearchCriteriaFlights
              value={searchCriteria}
              onChange={handleSearchCriteriaChange}
              searchCriteriaClassName="selectClassSpecial xs-12 sm-4 md-4 lg-4 xl-4 qq-resort-select"
            />
            <div className="selectSpecialCalendar xs-12 sm-7 md-7 xl-4">
              <Calendar
                defaultText={defaultText}
                iconCaret={iconCaret}
                iconCalendar={iconCalendar}
                saveInputDate={saveInputDate}
                theme={calendarTheme}
                checkinDateName=""
                checkoutDateName=""
                minDate={minDate}
                maxDate={maxDate}
                minimumNights={minimumNights}
                monthsToDisplay={monthsToDisplay}
                vacationData={vacationData}
                saveVacationData={saveVacationData}
                onInputClick={onInputClick}
                alignment="float"
                onApplyDates={onApplyDates}
                onClearDates={onClearDates}
                onCloseCalendar={onCloseCalendar}
                resetInput={resetQQ}
                resortOptions={resortOptions}
                showPricePerDay={!withFlightsVisible}
                showNightsLimit
              />
            </div>
            <WithFlightsElementV2
              id={withFlightsId[0]}
              WithFlightsElementClassName="selectSpecialWithFlights xs-12 sm-5 md-5 lg-4 xl-3"
              value={withFlightsVisible}
              onClick={toggleWithFlights}
            />
            <PromotionalCode
              promocodeClassName="specialPromoCode xs-12 sm-4 md-5 xl-3"
              onChange={handlePromocodeChange}
            />
            <SelectNumberRooms
              roomsClassName="xs-5 sm-3 md-3 xl-2"
              roomsId={withFlightsId[1]}
              onChange={selectQuantityRooms}
            />
            {/* <div className="selectPromoRoom xs-12 xl-6 md-10 xl-4 flexBasisCorrection"></div> */}
          </div>
          <div>
            <MainButton
              additionalClassName="specialsButton"
              copy={mainButton}
              bottomElement={bottomElement}
              vacationData={vacationData}
              buttonAction={buttonAction}
              targetBlank={targetBlank}
              customCallback={customCallback}
              horizontal
            />
          </div>
        </div>
      )}

      {withFlightsVisible && (
        <div className="center-lg">
          <div className="row special-horizontal-qq">
            <div className="selectSpecialResort-WFV xs-12 sm-4 md-4 lg-4 xl-3">
              <ResortSelect
                alignment="horizontal"
                resortOptions={resortOptions}
                vacationData={vacationData}
                saveVacationData={saveVacationData}
                onInputClick={onInputClick}
                resetInput={resetQQ}
              />
            </div>
            <div className="selectSpecialCalendar-WFV xs-12 sm-7 md-7 xl-4">
              <Calendar
                defaultText={defaultText}
                iconCaret={iconCaret}
                iconCalendar={iconCalendar}
                saveInputDate={saveInputDate}
                theme={calendarTheme}
                checkinDateName=""
                checkoutDateName=""
                minDate={minDate}
                maxDate={maxDate}
                minimumNights={minimumNights}
                monthsToDisplay={monthsToDisplay}
                vacationData={vacationData}
                saveVacationData={saveVacationData}
                onInputClick={onInputClick}
                alignment="float"
                onApplyDates={onApplyDates}
                onClearDates={onClearDates}
                onCloseCalendar={onCloseCalendar}
                resetInput={resetQQ}
                resortOptions={resortOptions}
                showPricePerDay={!withFlightsVisible}
                showNightsLimit
              />
            </div>
            <WithFlightsElementV2
              id={withFlightsId[0]}
              WithFlightsElementClassName="selectSpecialWithFlights-WFV xs-12 sm-5 md-5 lg-4 xl-3"
              value={withFlightsVisible}
              onClick={toggleWithFlights}
            />
            <PromotionalCode
              promocodeClassName="specialPromoCode-WFV xs-12 sm-4 md-5 xl-3"
              onChange={handlePromocodeChange}
            />
            <SelectNumberRooms
              roomsClassName="xs-6 sm-3 md-3 xl-2"
              roomsId={withFlightsId[1]}
              onChange={selectQuantityRooms}
            />
          </div>
          <div>
            <MainButton
              additionalClassName="specialsButton-WFV"
              copy={mainButton}
              bottomElement={bottomElement}
              vacationData={vacationData}
              buttonAction={buttonAction}
              targetBlank={targetBlank}
              customCallback={customCallback}
              horizontal
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SpecificDatesHorizontalSpecialV2;
