import React, { useState, useEffect, createRef } from 'react';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import yupValidations from '~/js/components/global/form/utils/formValidations';
import formActions from '~/js/components/global/form/utils/formActions';
import ReCAPTCHAv2 from '~/js/components/global/form/ReCAPTCHAv2';
import useWindowDimensions from '~/js/modules/hooks/useWindowDimensions';
import Submit from '~/js/components/global/form/Submit';
import ReactDOM from 'react-dom';
import axios from 'axios';

import { TextInput, Checkbox } from '~/js/components/global/form/InputWrapper';

const { errorChecker } = formActions;
const environment = window.sandals_app.env;

const DEFAULTACCOUNTSLINK = '/my-account';

const accountsCMSURL =
  environment === 'PRODUCTION'
    ? 'https://accountscms.sandals.co.uk/api/'
    : 'https://tstaccountscms.sandals.co.uk/api/';

export const GcvForm = ({ accountsInformation }) => {
  // Data from Strapi
  const accountsLogout = accountsInformation[1];

  const passwordUrl = accountsInformation[2].url;
  const userUrl = accountsInformation[3].url;
  const enrollUrl = accountsInformation[4].url;
  const recaptchaRef = createRef();
  const [gcvErrors] = useState({});
  const [unauthorizedMessage, setUnathorizedMessage] = useState(false);
  const [activateRecaptcha, setActivateRecaptcha] = useState(false); // Needs to be changed
  const [passwordShown, setPasswordShown] = useState(false);

  const { width } = useWindowDimensions();
  const isMobile = width < 768;

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const userProfileMenuInformation = async () => {
    const config = {
      method: 'GET',
      timeout: 120000,
      url: accountsCMSURL + 'layout?locale=en-gb',
    };

    const response = await axios(config)
      .then(res => {
        return res;
      })
      .catch(error => {
        console.log('error: ', error);
      });

    return response;
  };

  const profilePoints = async () => {
    const config = {
      method: 'GET',
      timeout: 120000,
      url: accountsCMSURL + 'loyalty/points',
      withCredentials: true,
    };

    const response = await axios(config)
      .then(res => {
        return res;
      })
      .catch(error => {
        console.log('error: ', error);
      });

    return response;
  };

  const profileInformation = async () => {
    const config = {
      method: 'GET',
      timeout: 120000,
      url: accountsCMSURL + 'loyalty/profiles',
      withCredentials: true,
    };

    const response = await axios(config)
      .then(res => {
        return res;
      })
      .catch(error => {
        console.log('error: ', error);
      });

    const userLabel = document.getElementById('accounts-user');
    const signInLabel = document.getElementById('accounts');
    const userInformationContainer = document.getElementById(
      'js-accounts-user__content'
    );

    if (response?.data?.status === 200) {
      userLabel.textContent =
        'Hi, ' + response.data.content.primary_member.first_name;

      if (userLabel.classList.contains('hide-item')) {
        userLabel.classList.toggle('hide-item');
      }

      if (!signInLabel.classList.contains('hide-item')) {
        signInLabel.classList.toggle('hide-item');
      }

      const userPointsResponse = await profilePoints();
      const userMenuLinksResponse = await userProfileMenuInformation();

      ReactDOM.render(
        <AccountInformation
          userProfileInfo={response}
          userProfilePoints={userPointsResponse.data}
          userProfileMenu={userMenuLinksResponse.data}
          profileInformation={profileInformation}
          accountsLogout={accountsLogout}
        />,
        userInformationContainer
      );
    } else {
      const signInIcon = document.createElement('i');

      signInIcon.classList.add('ic');
      signInIcon.classList.add('ic-sign-in');

      signInLabel.innerHTML = '';
      signInLabel.append(signInIcon);
      signInLabel.append('SSG Sign up/Login');

      if (!userLabel.classList.contains('hide-item')) {
        userLabel.classList.toggle('hide-item');
      }

      if (signInLabel.classList.contains('hide-item')) {
        signInLabel.classList.toggle('hide-item');
      }
    }
  };

  const submitForm = async values => {
    const loginData = {
      username: values.username,
      password: values.password,
      recaptcha: values.recaptcha,
      staySignedIn: values.staySigned,
    };

    const loginDataNoRecaptcha = {
      username: values.username,
      password: values.password,
      staySignedIn: values.staySigned,
    };

    const config = {
      method: 'POST',
      timeout: 120000,
      url: accountsCMSURL + 'auth/sign-in/',
      withCredentials: true,
      data: activateRecaptcha ? loginData : loginDataNoRecaptcha,
    };

    await axios(config)
      .then(res => {
        if (res.status === 200) {
          if (res.data?.status === 401) {
            if (res.data?.content?.passwordPendingChange) {
              window.open(
                DEFAULTACCOUNTSLINK + '/forgot-password?isInvalid',
                '_self'
              );
            }
            if (res.data?.content?.maxAttemps) {
              setActivateRecaptcha(true);
              if (res.data?.statusText !== 'Recaptcha Error') {
                setUnathorizedMessage(res.data?.status === 401);
              }
              recaptchaRef.current && recaptchaRef.current.reset();
            } else {
              setUnathorizedMessage(res.data?.status === 401);
              recaptchaRef.current && recaptchaRef.current.reset();
            }
          } else if (res.data?.status === 200) {
            profileInformation();
            const backButton = document.getElementsByClassName('back__btn');

            backButton[1].click();
            const outsideClick = document.getElementById('js-outside__click');

            if (!isMobile) {
              outsideClick.click();
            }
            setUnathorizedMessage(false);
            setActivateRecaptcha(false);
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
    recaptchaRef.current && recaptchaRef.current.reset();
  };

  useEffect(() => {
    profileInformation();
  }, []);

  return (
    <div className="accounts__feature">
      <div className="accounts_form--container">
        <Formik
          initialValues={{
            type: 'ACCOUNTS',
            source: 'CURRENT',
            username: '',
            password: '',
            staySigned: false,
            recaptcha: '',
          }}
          validationSchema={Yup.object(
            activateRecaptcha
              ? {
                  username: yupValidations.string.required,
                  password: yupValidations.string.required,
                  recaptcha: yupValidations.recaptcha,
                }
              : {
                  username: yupValidations.string.required,
                  password: yupValidations.string.required,
                }
          )}
          validateOnMount
          onSubmit={submitForm}
        >
          {/* Formik's context */}
          {({
            touched,
            errors,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form className="accounts_form">
              <div className="container">
                <div className="row center-xs middle-xs-min-down">
                  <div className="xs-11 sm-5 lg-4 no-gutters-lg-min-up">
                    <label className="san-label accounts__title">sign in</label>

                    <TextInput
                      name="username"
                      type="text"
                      placeholder="Email Address"
                      required
                    />

                    <div className="password__wrapper">
                      <TextInput
                        name="password"
                        type={passwordShown ? 'text' : 'password'}
                        placeholder="Password"
                        required
                      />
                      <i
                        role="presentation"
                        aria-label="icon"
                        onClick={togglePassword}
                        className={
                          passwordShown
                            ? 'ic-password-hide'
                            : 'ic-password-show'
                        }
                      />
                    </div>

                    {unauthorizedMessage && (
                      <div className="row start-xs san-form-group is-invalid">
                        <div className="xs-12">
                          <p className="san-feedback generalMessage-signin">
                            Invalid credentials. The email and/or password you
                            entered is incorrect. Please try again.
                          </p>
                        </div>
                      </div>
                    )}

                    {activateRecaptcha && (
                      <div className="recaptcha__group">
                        <ReCAPTCHAv2
                          innerRef={recaptchaRef}
                          name="recaptcha"
                          handler={setFieldValue}
                          touched={touched.recaptcha}
                          error={errors.recaptcha}
                          accountsSitekey="6LdfKGIqAAAAAHm8PIztlqg7IJplWS4B1n_wDivW"
                        />
                      </div>
                    )}

                    <div className="checkboxes__group form__footer_checkbox">
                      <Checkbox
                        name="staySigned"
                        label={<span>Stay signed in</span>}
                      />
                    </div>

                    <Submit
                      label="Sign in to account"
                      className="accounts__submit xs-12"
                      readyForSubmit={errorChecker(errors, gcvErrors)}
                      errors={{ ...errors, ...gcvErrors }}
                      isSubmitting={isSubmitting}
                      setFieldTouched={setFieldTouched}
                    />

                    <div className="signin__recover">
                      <span>
                        Recover <a href={userUrl}>Username</a> or{' '}
                        <a href={passwordUrl}>Password</a>
                      </span>
                    </div>
                  </div>

                  <div className="xs-11 sm-7 lg-8 start-xs">
                    <div className="signin__create-account">
                      <h2>Become A Member</h2>
                      <p>
                        Unlock exclusive benefits by joining Sandals Select
                        Rewards.
                      </p>
                      <ul>
                        <li>Access Your Account To:</li>
                        <li>
                          <span>Check In Online</span>
                        </li>
                        <li>
                          <span>View Trip Details</span>
                        </li>
                        <li>
                          <span>Track Your Reward Points</span>
                        </li>
                        <li>
                          <span>Book Holiday Extras</span>
                        </li>
                        <li>
                          <span>Refer Your Friends</span>
                        </li>
                        <li>
                          <span>And More!</span>
                        </li>
                      </ul>
                      <a href={enrollUrl}>
                        Create your account{' '}
                        <i className="link__icon ic ic-right-arrow" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const AccountInformation = ({
  userProfileMenu,
  userProfilePoints,
  profileInformation,
  accountsLogout,
}) => {
  const { data } = userProfileMenu;
  const { modules } = data;

  const userProfileLogout = async () => {
    const config = {
      method: 'GET',
      timeout: 120000,
      url: accountsCMSURL + 'auth/sign-out',
      withCredentials: true,
    };

    await axios(config)
      .then(res => {
        if (res.data.status === 200) {
          profileInformation();

          const userProfile = document.getElementById('accounts-user');

          userProfile.click();
        }

        return res;
      })
      .catch(error => {
        console.log('error: ', error);
      });
  };

  return (
    <div className="accounts-information">
      <div className="accounts-information__points">
        <span>
          Available Points Balance:{' '}
          <b>
            {userProfilePoints.content.points_available.toLocaleString('en-US')}
          </b>
        </span>
        <span>
          Points Value:{' '}
          <b>
            {' $'}
            {userProfilePoints.content.points_available_usd.toLocaleString(
              'en-US'
            )}
            {' (USD)'}
          </b>
        </span>
      </div>

      <div className="accounts-information__menu">
        {modules[1].links.map(menuItem => {
          return (
            <a key={menuItem.label} href={DEFAULTACCOUNTSLINK + menuItem.href}>
              <div className="image-container">
                <img src={menuItem.logo.url} />
              </div>
              {menuItem.label}
            </a>
          );
        })}
        <button onClick={() => userProfileLogout()}>
          <div className="image-container image-logout">
            <img src={accountsLogout.url} />
            {modules[1].logOut.label}
          </div>
        </button>
      </div>
    </div>
  );
};

export default GcvForm;
