/**
 * Global Tooltip
 */
import React, { useEffect, useRef, useState } from 'react';
import OutsideAlerter from '~/js/components/global/outside-alerter/OutsideAlerter';
import PropTypes from 'prop-types';

const Tooltip = props => {
  const [tooltip, setTooltip] = useState(false);
  const [coordinates, setCoordinates] = useState([]);
  const { className, content, disableOutsideAlerter, onClick, type } = props;
  const buttonRef = useRef();
  const tooltipRef = useRef(null);

  function tooltipHandler(event) {
    if (event.keyCode === 13 || event.type === 'click') {
      setCoordinates([event.clientX, event.clientY]);
      setTooltip(!tooltip);
    }
    onClick && onClick();
  }

  const outsideClick = () => {
    setTooltip(!tooltip);
    onClick && onClick();
  };

  useEffect(() => {
    if (type === 'resortsMap') {
      window.addEventListener(
        'scroll',
        e => {
          if (tooltip) {
            outsideClick();
            setCoordinates([]);
          }
        },
        true
      );

      return function cleanUp() {
        window.removeEventListener('scroll', () => {});
      };
    }
  });

  useEffect(() => {
    if (tooltip && tooltipRef && type === 'resortsMap') {
      tooltipRef.current.style.left = `${coordinates[0] - 2}px`;
      tooltipRef.current.style.top = `${coordinates[1] - 10}px`;
      tooltipRef.current.style.position = 'fixed';
      tooltipRef.current.style.margin = '0';
    }
  }, [tooltip, tooltipRef]);

  return (
    <>
      <button
        onKeyPress={e => tooltipHandler(e)}
        onClick={tooltipHandler}
        className={`${className}__button`}
        ref={buttonRef}
      >
        {props.children}
      </button>

      {tooltip && (
        <OutsideAlerter
          handler={disableOutsideAlerter ? () => {} : outsideClick}
        >
          <div
            className={`${
              className ? `${className}__tooltip` : ''
            } tooltip xs-12`}
            ref={tooltipRef}
          >
            <div className="tooltip__content pos-rel">
              <button
                onClick={tooltipHandler}
                className="tooltip__button ic ic-close-narrow"
              />
              {content}
            </div>
          </div>
        </OutsideAlerter>
      )}
    </>
  );
};

Tooltip.propTypes = {
  className: PropTypes.string,
  alignment: PropTypes.string,
  theme: PropTypes.string,
};

export default Tooltip;
