import MicroModal from 'micromodal';

const microModal = () => {
  const closeTrigger = document.querySelectorAll('.modal__close');
  const alternateCloseTrigger = document.querySelectorAll(
    '.modal__close__button'
  );
  const backdropCloseTrigger = document.querySelectorAll('.modal__backdrop');

  function closeModalHandler() {
    closeTrigger.forEach(btn => {
      btn.click();
    });

    const iframeExists = !!document.getElementById('modal__iframe');
    const videoNotIframeExists = !!document.getElementById('modal__vrx_video');

    if (iframeExists) {
      const mediaPlayer = document.querySelectorAll('.modal__iframe');

      mediaPlayer.forEach(iframe => {
        const iframeSrc = iframe.src;

        iframe.src = iframeSrc;
      });
    }
    if (videoNotIframeExists) {
      const mediaPlayer = document.querySelectorAll('.modal__vrx_video');

      mediaPlayer.forEach(iframe => {
        iframe.pause();
        iframe.currentTime = 0;
      });
    }
  }

  const playButton = document.querySelectorAll(
    '.item__play-icon.modal__autoplay'
  );

  if (playButton) {
    playButton.forEach(button => {
      button.addEventListener('click', e => {
        const modalId = e.target.closest('.item__play-icon.modal__autoplay')
          .dataset.micromodalTrigger;

        const mediaPlayer = document.querySelector(
          `#${modalId} .modal__vrx_video`
        );

        if (mediaPlayer?.dataset.autoplay) {
          mediaPlayer.play();
        }
      });
    });
  }

  alternateCloseTrigger.forEach(btn => {
    btn.addEventListener('click', () => {
      closeModalHandler();
    });
  });

  backdropCloseTrigger.forEach(btn => {
    btn.addEventListener('click', () => {
      closeModalHandler();
    });
  });

  MicroModal.init({
    disableScroll: true,
    disableFocus: true,
  });
};

export default microModal;
