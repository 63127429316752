import React, { useState, useRef, Fragment } from 'react';

import PropTypes from 'prop-types';
import useWindowDimensions from '~/js/modules/hooks/useWindowDimensions';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import MagicSliderDots from 'react-magic-slider-dots';
import 'react-magic-slider-dots/dist/magic-dots.css';

const SliderWrapper = props => {
  /**
   * Merge two objects
   * @param {Object} source - mutable object
   * @param {Object} merged - merged object
   * @return {Object}
   */
  const merge = (source, merged) => {
    for (const key in merged) {
      if (merged.hasOwnProperty(key)) {
        if (
          merged[key] instanceof Object &&
          merged[key].constructor.name === 'Object'
        ) {
          if (typeof source[key] !== 'object') {
            source[key] = {};
          }
          source[key] = merge(source[key], merged[key]);
        } else {
          source[key] = merged[key];
        }
      }
    }

    return source;
  };

  const ref = useRef();

  const {
    children,
    settings: receivedSettings,
    showCounter,
    showCounterClass,
    showCounterIcon,
    slickGoTo,
    specification,
  } = props;

  const defaultSettings = {
    arrows: true,
    dots: true,
    initialSlide: 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    centerPadding: '50px',
    infinite: children.length > 3 ? true : false,
    fade: false,
    autoplay: false,
    speed: 400,
    autoplaySpeed: 4000,
    variableWidth: false,
    adaptiveHeight: false,
    useTransform: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    touchThreshold: 20,
    className: '',
    cssEase: 'ease',
    lazyLoad: 'ondemand',
    dotsSettings: {
      position: 'default',
      theme: 'default',
    },
    arrowsSettings: {
      type: 'default',
      shadow: true,
    },
    responsive: null,
  };

  const willBeAppliedSettings = merge({}, defaultSettings);

  merge(willBeAppliedSettings, receivedSettings);

  willBeAppliedSettings.responsive = null;

  let numDotsToShow = 3;

  let dotWidth = 20;

  const fixAlignment = screenWidth => {
    const sliders = document.getElementsByClassName('slick-slider');

    for (const slider of sliders) {
      const slickTrack = slider.querySelector('.slick-track');
      const slickTrackWidth = slickTrack.offsetWidth - 2;
      const slickSlides = slickTrack.querySelectorAll('.slick-slide');
      const slickSlidesLength = slickSlides.length;
      const slickSlideWidth = slickSlides[0]?.offsetWidth;
      const sliderShift =
        (slickTrackWidth - slickSlideWidth * slickSlidesLength) / 2;

      if (slickSlidesLength <= 3) {
        if (slickTrackWidth <= screenWidth) {
          for (const slide of slickSlides) {
            slide.style.transform = `translate3d(${sliderShift}px, 0px, 0px)`;
          }
        } else {
          for (const slide of slickSlides) {
            slide.style.transform = 'translate3d(0px, 0px, 0px)';
          }
        }
      }
    }
  };

  const checkResolution = () => {
    const { width: screenWidth } = useWindowDimensions();

    if (children.length < 6) {
      numDotsToShow = children.length;
    } else {
      numDotsToShow = 6;
    }

    if (screenWidth < 1460) {
      dotWidth = 15;
      willBeAppliedSettings.initialSlide = 0;
    } else {
      dotWidth = 20;
      willBeAppliedSettings.initialSlide = 1;
    }

    if (!willBeAppliedSettings.centerMode) {
      willBeAppliedSettings.initialSlide = 0;
    }

    if (children.length === 3 && willBeAppliedSettings.centerMode) {
      willBeAppliedSettings.initialSlide = 1;
    }

    if (children.length < 3) {
      willBeAppliedSettings.initialSlide = 0;
    }

    merge(willBeAppliedSettings, receivedSettings);

    if (receivedSettings.responsive) {
      receivedSettings.responsive.forEach(element => {
        if (screenWidth < element.breakpoint) {
          merge(willBeAppliedSettings, element.settings);
        }
      });
    }

    willBeAppliedSettings.responsive = null;

    setTimeout(() => fixAlignment(screenWidth), 500);
  };

  checkResolution();

  const [activeSlide, setActiveSlide] = useState(
    willBeAppliedSettings.initialSlide ? willBeAppliedSettings.initialSlide : 0
  );

  let leftArrowClasses = 'ic ic-slick-left-arrow';

  let rightArrowClasses = 'ic ic-slick-right-arrow';

  if (willBeAppliedSettings.arrowsSettings.type === 'custom') {
    leftArrowClasses = 'ic ic-left-arrow-rd';
    rightArrowClasses = 'ic ic-right-arrow-rd';
  }

  if (willBeAppliedSettings.arrowsSettings.shadow === false) {
    leftArrowClasses += ' no-shadow';
    rightArrowClasses += ' no-shadow';
  }

  const SlickArrowLeft = ({ currentSlide, slideCount, ...propsArrow }) => (
    <button
      {...propsArrow}
      data-current-slide={currentSlide}
      data-slide-count={slideCount}
      aria-disabled={
        propsArrow.className.indexOf('disabled') > 0 ? true : false
      }
      type="button"
    >
      <i role="presentation" aria-label="icon" className={leftArrowClasses} />
    </button>
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...propsArrow }) => (
    <button
      {...propsArrow}
      data-current-slide={currentSlide}
      data-slide-count={slideCount}
      aria-disabled={
        propsArrow.className.indexOf('disabled') > 0 ? true : false
      }
      type="button"
    >
      <i role="presentation" aria-label="icon" className={rightArrowClasses} />
    </button>
  );

  willBeAppliedSettings.prevArrow = <SlickArrowLeft />;
  willBeAppliedSettings.nextArrow = <SlickArrowRight />;

  let dotsClasses = 'magic-dots slick-dots';

  dotsClasses += ' dot-width-' + dotWidth;

  dotsClasses += ' slides-number-' + children.length;
  dotsClasses += ' dots-' + numDotsToShow;
  dotsClasses += ' initial-slide-index-' + willBeAppliedSettings.initialSlide;

  if (willBeAppliedSettings.dotsSettings.position === 'left') {
    dotsClasses += ' dots_left';
  }

  if (willBeAppliedSettings.dotsSettings.position === 'right') {
    dotsClasses += ' dots_right';
  }

  if (willBeAppliedSettings.dotsSettings.theme === 'light') {
    dotsClasses += ' dots_light';
  }

  if (willBeAppliedSettings.dotsSettings.theme === 'dark') {
    dotsClasses += ' dots_dark';
  }

  if (willBeAppliedSettings.dotsSettings.theme === 'tabs') {
    dotsClasses = 'dots_tab';
  }

  const fixAccessibility = () => {
    const sliders = document.getElementsByClassName('slick-slider');

    for (const slider of sliders) {
      const elements = slider.querySelectorAll('.slick-slide[tabindex="-1"]');

      for (const element of elements) {
        const selectorA = element.querySelector('a');
        const selectorB = element.querySelector('button');

        if (selectorA) {
          selectorA.tabIndex = -1;
        }

        if (selectorB) {
          selectorB.tabIndex = 0;
        }
      }

      const activeSlides = slider.querySelectorAll('.slick-slide.slick-active');

      for (const element of activeSlides) {
        const selectorA = element.querySelector('a');
        const selectorB = element.querySelector('button');

        if (selectorA) {
          selectorA.tabIndex = 0;
        }

        if (selectorB) {
          selectorB.tabIndex = 0;
        }
      }
    }
  };

  const sliderSettings = {
    ...willBeAppliedSettings,
    onInit: () => {
      fixAccessibility();
    },
    afterChange: willBeAppliedSettings.afterChange
      ? current => {
          willBeAppliedSettings.afterChange(current);
          setActiveSlide(current);
          fixAccessibility();
        }
      : current => {
          setActiveSlide(current);
          fixAccessibility();
        },
    appendDots: dots => {
      return (
        <MagicSliderDots
          dots={dots}
          numDotsToShow={numDotsToShow}
          dotWidth={dotWidth}
          dotContainerClassName={dotsClasses}
        />
      );
    },
  };

  if (slickGoTo && ref && ref.current) {
    ref.current.slickGoTo(willBeAppliedSettings.initialSlide);
  }

  return (
    <Fragment>
      <Slider ref={ref} {...sliderSettings}>
        {children}
      </Slider>
      {showCounter ? (
        <div
          className={`pos-abs flx ${
            showCounterClass ? showCounterClass : 'default'
          }__slick-counter slick-counter`}
        >
          <div className="counter__wrap">
            {showCounterIcon && (
              <i
                className={`ic ${showCounterIcon} pos-rel slick-counter__icon`}
              />
            )}
            <span className="slick-counter__cur-slide">{`${
              activeSlide + 1
            }`}</span>
            <span className="slick-counter__divider">/</span>
            <span className="slick-counter__last-slide">{children.length}</span>
            {specification && (
              <Fragment>
                <span className="specification__title">
                  {specification[activeSlide].title}&nbsp;
                </span>
                <div
                  className="specification__subtitle"
                  dangerouslySetInnerHTML={{
                    __html: specification[activeSlide].description,
                  }}
                />
              </Fragment>
            )}
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

SliderWrapper.defaultProps = {
  settings: {},
  showCounter: false,
};

SliderWrapper.propTypes = {
  children: PropTypes.array,
  showCounterClass: PropTypes.string,
  showCounterIcon: PropTypes.string,
  slickGoTo: PropTypes.bool,
  specification: PropTypes.array,
  settings: PropTypes.object,
  showCounter: PropTypes.bool,
};

export default SliderWrapper;
