import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import m from 'moment';
import 'react-dates/initialize';
import { DayPickerRangeController } from 'react-dates';
import Portal from '~/js/components/global/portal/Portal';
import useDisabledScroll from '~/js/modules/hooks/useDisabledScroll';
import useWindowDimensions from '~/js/modules/hooks/useWindowDimensions';
import Wrapper from '~/js/components/global/wrapper/Wrapper';
import {
  obeFormat,
  defaultFormat,
  dateFormatter,
} from '~/js/components/resorts/rooms/utils/dateUtils';
import {
  HORIZONTAL_ORIENTATION,
  VERTICAL_SCROLLABLE,
  VERTICAL_ORIENTATION,
  isInclusivelyAfterDay,
} from './reactDatesUtilities';
import useScrollIntoView from '~/js/modules/hooks/useScrollIntoView';
import ResolveImg from '~/js/components/global/image/ResolveImg';
import globalConfig from '../../../modules/global';
import aitacodeConverter from './aitacodeConverter';
import Tooltip from '~/js/components/global/tooltip/Tooltip';

const { data = {} } = window.sandals_app.page;
const { resorts, resort } = data;

const currentResort = resorts ? resorts[0] : resort;
const resortCode = currentResort?.code;

const Calendar = ({
  theme,
  minDate,
  maxDate,
  monthsToDisplay,
  minimumNights,
  checkinDateName,
  checkoutDateName,
  alignment = 'right',
  iconCaret,
  iconCalendar,
  saveInputDate = true,
  vacationData,
  saveVacationData,
  onInputClick,
  portalClass = 'default__portal',
  onApplyDates = () => {},
  onClearDates = () => {},
  resetInput,
  horizontalSpecialAlignment,
  styledCustomClass = false,
  resortOptions,
  showNightsLimit = false,
  showPricePerDay = false,
  // isCallendarUk,
}) => {
  const { DEFAULTS } = globalConfig;

  const defoCheckinDate = 'Check-In';
  const defoCheckoutDate = 'Check-Out';
  const defoSingleInputCheckDate = `${defoCheckinDate} & ${defoCheckoutDate}`;
  const resortCodeOption =
    resortOptions.length > 0 ? vacationData.rstCode : resortCode;

  const mMinDate = m(
    minDate || resortCodeOption === 'SSV'
      ? DEFAULTS.minDateSSV
      : DEFAULTS.minDate,
    'MM-DD-YYYY',
    true
  );
  const mMaxDate = m(maxDate || DEFAULTS.maxDate, 'MM-DD-YYYY', true);

  const [checkinDate, setCheckinDate] = useState(defoCheckinDate);
  const [checkoutDate, setCheckoutDate] = useState(defoCheckoutDate);
  const [singleInputCheckDate, setSingleInputCheckDate] = useState(
    defoSingleInputCheckDate
  );
  const [calendarStartDate, setCalendarStartDate] = useState(null);
  const [calendarEndDate, setCalendarEndDate] = useState(null);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [prevVisible, setPrevVisible] = useState(false);
  const [nextVisible, setNextVisible] = useState(true);
  const [focusedInput, setFocusedInput] = useState('startDate');
  const [size, setSize] = useState([0, 0]);
  const [showMaxNightsError, setShowMaxNightsError] = useState(false);
  const [showMinNightsError, setShowMinNightsError] = useState(false);
  const [disabledScroll, setDisabledScroll] = useState(false);
  const [duration, setDuration] = useState('7');
  const [nightsRates, setNightsRates] = useState([]);
  const [currentDate, setCurrentDate] = useState('');

  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const singleInputDateRef = useRef(null);
  const calendarRef = useRef(null);
  const alertRef = useRef(null);
  const { width } = useWindowDimensions();
  const isMobile = width < 768;
  const isTablet = width < 992;
  const CalendarWrapper = isMobile ? Portal : Wrapper;

  const [sanFormat, setSanFormat] = useState(
    styledCustomClass && isTablet ? 'MMM DD, YYYY' : 'ddd, MMM DD, YYYY'
  );

  useDisabledScroll(disabledScroll);
  useScrollIntoView(singleInputDateRef, calendarVisible, true);

  const fetchNightsRates = async (date = mMinDate) => {
    const { value } = aitacodeConverter(vacationData.rstCode);
    const months = [date];

    if (months.length === 1) {
      months.push(m(date).add(1, 'month'));
    } else {
      months.push(date);
    }

    const priceAPI = '//fusionapi.traveltek.net/2.0/json/sandalscalender.pl?';
    const params = {
      depair: vacationData.gateway,
      type: 'flighthotel',
      flightclass: vacationData.seatType,
      hotelcode: value,
      nights: duration,
      site: 'tstnews.sandals.co.uk',
    };

    const promises = months.map(monthData => {
      const queryParams = {
        ...params,
        month: m(monthData).format('M'),
        year: m(monthData).format('YYYY'),
      };

      const url = priceAPI + new URLSearchParams(queryParams);

      return fetch(url)
        .then(response => response.json())
        .then(json => {
          if (json.results) {
            return json.results;
          }

          return null;
        })
        .catch(error => {
          console.log(error.message);
          if (error.name === 'AbortError') {
            return null;
          }
          throw error;
        });
    });

    Promise.all(promises)
      .then(results => {
        const combinedResults = results.flatMap(result => result || []);

        setNightsRates(combinedResults);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const checkNavsVisibility = date => {
    const maxDateClone = mMaxDate.clone();
    const maxDateMinusMonths = maxDateClone.subtract(2, 'months');

    let prvVisible = true;

    let nxtVisible = true;

    // added the second parameter 'month' to compare only months
    // displaying of arrows for navigation through months dependes only on months
    // more info about momentjs: https://momentjscom.readthedocs.io/en/latest/moment/05-query/03-is-after/

    if (date.isAfter(mMinDate, 'month')) {
      prvVisible = true;
    } else {
      prvVisible = false;
    }

    if (date.isBefore(maxDateMinusMonths, 'month')) {
      nxtVisible = true;
    } else {
      nxtVisible = false;
    }

    if (showPricePerDay) {
      setCurrentDate(date);
    }
    setPrevVisible(prvVisible);
    setNextVisible(nxtVisible);
  };

  const hideCalendar = () => {
    setCalendarVisible(false);
    setDisabledScroll(false);
  };

  const showCalendar = () => {
    // check visibility of arrows right on displaying calendar without waiting for another events
    if (!calendarVisible) {
      checkNavsVisibility(calendarStartDate ? calendarStartDate : mMinDate);
    }
    setCalendarVisible(true);
  };

  const handleApplyDates = () => {
    hideCalendar();
    onApplyDates({ checkinDate, checkoutDate });
  };

  const [openAlert, setOpenAlert] = useState(false);

  const toggleAlert = () => {
    setOpenAlert(!openAlert);
  };

  const alert = () => {
    return (
      <>
        <div ref={alertRef} className="custom-alert">
          <p className="custom-alert-content">
            Select Resort and Flights Departing
          </p>
          <button type="button" className="alert-close" onClick={toggleAlert}>
            Ok
          </button>
        </div>
        <div className="alert-overlay" />
      </>
    );
  };

  const handleToggleCalendar = e => {
    const defoRef = { contains: () => false };
    const singleInput = singleInputDateRef.current || defoRef;
    const startInput = startDateRef.current || defoRef;
    const endInput = endDateRef.current || defoRef;
    const calendar = calendarRef.current || defoRef;
    const alertR = alertRef.current || defoRef;

    if (e) {
      const inputClicked =
        singleInput.contains(e.target) ||
        startInput.contains(e.target) ||
        endInput.contains(e.target);
      const calendarClicked = calendar.contains(e.target);
      const alertClicked = alertR.contains(e.target);

      if (inputClicked) {
        if (
          (!alertClicked && showPricePerDay && !vacationData.rstCode) ||
          (!alertClicked && showPricePerDay && !vacationData.gateway)
        ) {
          setOpenAlert(true);

          return;
        }

        if (showPricePerDay && vacationData.rstCode && vacationData.gateway) {
          showCalendar();
        } else if (!showPricePerDay) {
          showCalendar();
        }

        if (isMobile) {
          setDisabledScroll(true);
        }
      }

      if (!inputClicked && !calendarClicked) {
        hideCalendar();
      }
    }
  };

  const clearDates = () => {
    setCalendarStartDate(null);
    setCalendarEndDate(null);
    setCheckinDate(defoCheckinDate);
    setCheckoutDate(defoCheckoutDate);
    setSingleInputCheckDate(defoSingleInputCheckDate);
    sessionStorage.removeItem('startDate');
    sessionStorage.removeItem('endDate');
    onClearDates();
  };

  const clearHoveredDays = () => {
    const hoveredDays = document.querySelectorAll('.CalendarDay__hovered_span');

    hoveredDays.forEach(item =>
      item.classList.remove('CalendarDay__hovered_span')
    );
  };

  const handleDatesChange = ({ startDate, endDate }) => {
    let cloneStartDate = startDate;

    if (!startDate?.format('MM-DD-YYYY')) {
      cloneStartDate = endDate;
    }

    const checkInDate = startDate
      ? startDate?.format('MM-DD-YYYY')
      : endDate.format('MM-DD-YYYY');
    const checkOutDate = endDate ? endDate.format('MM-DD-YYYY') : '';
    const sanFormatCheckInDate = startDate
      ? startDate?.format(sanFormat)
      : endDate.format(sanFormat);

    let nightsDiff = 0;

    // check if max 21 nights stay
    if (endDate) {
      nightsDiff = endDate.diff(startDate, 'days', true);
    }

    if (cloneStartDate && endDate === null) {
      clearDates();
      setTimeout(() => {
        clearHoveredDays();
      }, 300);
    }

    setCalendarStartDate(cloneStartDate);
    setCheckinDate(sanFormatCheckInDate);

    if (showNightsLimit && duration !== 'otherDuration') {
      const sanFormatCheckOutDate = m(sanFormatCheckInDate).add(
        +duration,
        'days'
      );
      const sanFormatSingleInputCheckDate = `${sanFormatCheckInDate} - ${sanFormatCheckOutDate.format(
        sanFormat
      )}`;

      setCalendarEndDate(sanFormatCheckOutDate);

      setCheckoutDate(sanFormatCheckOutDate.format(sanFormat));
      setSingleInputCheckDate(sanFormatSingleInputCheckDate);

      saveVacationData({
        checkInDate,
        checkOutDate: sanFormatCheckOutDate.format('MM-DD-YYYY'),
      });

      if (saveInputDate) {
        // Set dates to sessionStorage
        const savedCheckIn = dateFormatter(
          cloneStartDate,
          defaultFormat,
          obeFormat
        );
        const savedCheckOut = dateFormatter(
          sanFormatCheckOutDate,
          defaultFormat,
          obeFormat
        );

        if (sanFormatCheckOutDate._isValid) {
          sessionStorage.setItem('startDate', savedCheckIn);
          sessionStorage.setItem('endDate', savedCheckOut);
          sessionStorage.setItem('generalRstCode', resortCodeOption);
        }
      }
    } else if (nightsDiff >= 3 && nightsDiff <= 21) {
      const sanFormatCheckOutDate = endDate ? endDate.format(sanFormat) : '';
      const sanFormatSingleInputCheckDate = `${sanFormatCheckInDate} - ${
        sanFormatCheckOutDate ? sanFormatCheckOutDate : ''
      }`;

      setCalendarEndDate(endDate);

      setCheckoutDate(sanFormatCheckOutDate);
      setSingleInputCheckDate(sanFormatSingleInputCheckDate);

      saveVacationData({ checkInDate, checkOutDate });

      if (saveInputDate) {
        // Set dates to sessionStorage
        const savedCheckIn = dateFormatter(startDate, defaultFormat, obeFormat);
        const savedCheckOut = dateFormatter(endDate, defaultFormat, obeFormat);

        if (startDate._isValid && endDate._isValid) {
          sessionStorage.setItem('startDate', savedCheckIn);
          sessionStorage.setItem('endDate', savedCheckOut);
          sessionStorage.setItem('generalRstCode', resortCodeOption);
        }
      }
      setShowMaxNightsError(false);
      setShowMinNightsError(false);
    } else if (nightsDiff > 1 && nightsDiff > 21) {
      // display error
      setShowMaxNightsError(true);
      setCalendarEndDate(null);
      setCheckoutDate('');
      clearHoveredDays();
    } else if (nightsDiff > 0 && nightsDiff < 3) {
      // display error
      setShowMinNightsError(true);
      setCalendarEndDate(null);
      setCheckoutDate('');
      clearHoveredDays();
    }
  };

  const isOutsideRange = day => {
    const isMinDate = !isInclusivelyAfterDay(day, minDate || mMinDate);
    const isMaxDate = isInclusivelyAfterDay(day, maxDate || DEFAULTS.maxDate);

    return isMinDate || isMaxDate;
  };

  const onNavClick = date => {
    checkNavsVisibility(date);
  };

  const onSelectChange = ({ month, onYearSelect }) => {
    return e => {
      const { value } = e.currentTarget;

      onYearSelect(month, Number(value));
      checkNavsVisibility(month.add(Number(value) - month.year(), 'year'));
    };
  };

  const renderMonthHeader = ({ month, onYearSelect }) => {
    const minDateYear = mMinDate.clone().year();
    const maxDateYear = mMaxDate.clone().year();
    const yearOptions = [];
    const currentYear = month.format('YYYY');

    for (let i = minDateYear; i < maxDateYear; ++i) {
      yearOptions.push(i);
    }

    const pricesInTargetMonth = nightsRates
      .filter(
        _ => m(_.date).isSame(month, 'month') && m(_.date).isSame(month, 'year')
      )
      .map(_ => parseInt(_.price, 10));

    let minPrice;

    if (pricesInTargetMonth.length > 0) {
      minPrice = Math.min(...pricesInTargetMonth).toLocaleString();
    }

    return (
      <>
        <div className="lowest-price">
          {minPrice && <>Lowest available price this month £{minPrice}PP</>}
        </div>
        <div className="qq-calendar-headers">
          <span>{month.format('MMMM')}</span>
          <div className="qq-input-wrap qq-select qq-header-select">
            <div className="select-wrapper with-two-arrows">
              <select
                name="qq-year-select"
                className="qq-input qq-year-select"
                value={currentYear}
                onChange={onSelectChange({ month, onYearSelect })}
              >
                {yearOptions.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleFocusChange = focusInput => {
    setFocusedInput(focusInput || 'startDate');
  };

  // const numberOfMonthsToShow =
  //   DEFAULTS.maxDate.diff(minDate || mMinDate, 'months') + 1;

  const handleCustomClick = e => {
    if (onInputClick && e.currentTarget.name) {
      onInputClick(e.currentTarget.name);
    }
  };

  const registerMouseDown = () => {
    document.addEventListener('mousedown', handleToggleCalendar);
    document.addEventListener('focusin', handleToggleCalendar);
  };

  const unRegisterMouseDown = () => {
    document.removeEventListener('mousedown', handleToggleCalendar);
    document.removeEventListener('focusin', handleToggleCalendar);
  };

  useEffect(() => {
    registerMouseDown();

    return () => {
      unRegisterMouseDown();
    };
  });

  useEffect(() => {
    if (resetInput) {
      hideCalendar();
      onClearDates();
      setCheckinDate(defoCheckinDate);
      setCheckoutDate(defoCheckoutDate);
      setSingleInputCheckDate(defoSingleInputCheckDate);
      setCalendarStartDate(null);
      setCalendarEndDate(null);
    }
  }, [resetInput]);

  useEffect(() => {
    setSanFormat(
      styledCustomClass && isTablet ? 'MMM DD, YYYY' : 'ddd, MMM DD, YYYY'
    );
    const sessionCheckIn = sessionStorage.getItem('startDate');
    const sessionCheckOut = sessionStorage.getItem('endDate');

    if (sessionCheckIn && sessionCheckOut) {
      const sanFormatCheckInDate = m(sessionCheckIn).format(sanFormat);

      const sanFormatCheckOutDate = sessionCheckOut
        ? m(sessionCheckOut).format(sanFormat)
        : '';
      const sanFormatSingleInputCheckDate = `${sanFormatCheckInDate} - ${
        sanFormatCheckOutDate ? sanFormatCheckOutDate : ''
      }`;

      setCheckinDate(sanFormatCheckInDate);
      setCheckoutDate(sanFormatCheckOutDate);
      setSingleInputCheckDate(sanFormatSingleInputCheckDate);
    }
  }, [width]);

  useEffect(() => {
    if (saveInputDate) {
      const sessionCheckIn = sessionStorage.getItem('startDate');
      const sessionCheckOut = sessionStorage.getItem('endDate');

      if (
        resortCodeOption === 'SSV' &&
        m(sessionCheckIn).isBefore(DEFAULTS.minDateSSV)
      ) {
        clearDates();

        return;
      }

      if (sessionCheckIn && sessionCheckOut) {
        const sanFormatCheckInDate = m(sessionCheckIn).format(sanFormat);

        const sanFormatCheckOutDate = sessionCheckOut
          ? m(sessionCheckOut).format(sanFormat)
          : '';
        const sanFormatSingleInputCheckDate = `${sanFormatCheckInDate} - ${
          sanFormatCheckOutDate ? sanFormatCheckOutDate : ''
        }`;

        setCalendarStartDate(m(sessionCheckIn));
        setCalendarEndDate(m(sessionCheckOut));
        setCheckinDate(sanFormatCheckInDate);
        setCheckoutDate(sanFormatCheckOutDate);
        setSingleInputCheckDate(sanFormatSingleInputCheckDate);

        handleDatesChange({
          startDate: m(sessionCheckIn),
          endDate: m(sessionCheckOut),
        });
      }
    }
  }, []);

  useEffect(() => {
    const sessionCheckIn = sessionStorage.getItem('startDate');

    if (
      resortCodeOption === 'SSV' &&
      m(sessionCheckIn).isBefore(DEFAULTS.minDateSSV)
    ) {
      clearDates();

      return;
    }
  }, [resortCodeOption]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    setNightsRates([]);
  }, [showPricePerDay]);

  function handleDuration(e) {
    const nights = e.target.value;

    setDuration(nights);

    if (nights === 'otherDuration') {
      setNightsRates([]);
    }
  }

  useEffect(() => {
    const sessionCheckIn = sessionStorage.getItem('startDate');

    if (currentDate && duration !== 'otherDuration') {
      fetchNightsRates(currentDate);
    }
    if (duration !== 'otherDuration' && sessionCheckIn) {
      handleDatesChange({
        startDate: m(sessionCheckIn),
      });
    }
  }, [duration, currentDate]);

  function renderDayContents(day) {
    const isDate = nightsRates.find(
      itemDay => itemDay.date === day.format('YYYY-MM-DD')
    );

    if (isDate?.price) {
      const price = parseInt(isDate.price, 10).toLocaleString();

      return (
        <div className="day">
          <span>{day.format('D')}</span>
          <span className="day-price">£ {price}</span>
        </div>
      );
    }

    return <span className="day">{day.format('D')}</span>;
  }

  const tooltipContent = (
    <div className="tooltip__wrap">
      <h2 className="title">
        Prices shown are per person and show the cheapest available price based
        on 2 persons sharing a room for your selected duration
      </h2>
    </div>
  );

  return (
    <div className={`row center-xs top-xs qq-calendar ${theme}`}>
      {openAlert && alert()}
      {checkinDateName && (
        <div
          className={`xs-12${
            horizontalSpecialAlignment ? ' md-6' : ''
          } qq-input-wrapper qq-input-${checkinDateName}-wrapper`}
        >
          <button
            name={checkinDateName}
            id={checkinDateName}
            className={`qq-input ${checkinDateName}`}
            ref={startDateRef}
            onMouseDown={handleCustomClick}
          >
            {checkinDate}
          </button>
          {iconCaret && (
            <i role="presentation" aria-label="icon" className=" qq-icon" />
          )}
          {iconCalendar && (
            <ResolveImg
              imgData={{
                src: 'https://cdn.sandals.com/sandals/v13/images/EN/global/elements/calendar.svg',
                alt: 'calendar',
                className: 'qq-icon js-resolve',
              }}
            />
          )}
        </div>
      )}
      {checkoutDateName && (
        <div
          className={`xs-12${
            horizontalSpecialAlignment ? ' md-6' : ''
          } qq-input-wrapper qq-input-${checkoutDateName}-wrapper`}
        >
          <button
            name={checkoutDateName}
            id={checkoutDateName}
            className={`qq-input ${checkoutDateName}`}
            ref={endDateRef}
            onMouseDown={handleCustomClick}
          >
            {checkoutDate}
          </button>
          {iconCaret && (
            <i role="presentation" aria-label="icon" className=" qq-icon" />
          )}
          {iconCalendar && (
            <ResolveImg
              imgData={{
                src: 'https://cdn.sandals.com/sandals/v13/images/EN/global/elements/calendar.svg',
                alt: 'calendar',
                className: 'qq-icon js-resolve',
              }}
            />
          )}
        </div>
      )}
      {!checkinDateName && !checkoutDateName && (
        <div className="xs-12 qq-input-wrapper">
          <button
            name="checkin-out-date"
            id="checkin-out-date"
            className="qq-input checkin-out-date"
            ref={singleInputDateRef}
            onMouseDown={handleCustomClick}
          >
            {singleInputCheckDate}
          </button>
          {iconCaret && (
            <i role="presentation" aria-label="icon" className=" qq-icon" />
          )}
          {iconCalendar && (
            <ResolveImg
              imgData={{
                src: 'https://cdn.sandals.com/sandals/v13/images/EN/global/elements/calendar.svg',
                alt: 'calendar',
                className: 'qq-icon js-resolve',
              }}
            />
          )}
        </div>
      )}
      {calendarVisible && (
        <CalendarWrapper id={isMobile ? 'qq-calendar' : undefined}>
          <div
            ref={calendarRef}
            className={`qq-calendar-wrapper align-to-${alignment} fix-viewport-size ${portalClass}`}
            style={
              isMobile
                ? { height: `${size[1]}px` }
                : showNightsLimit
                ? { height: '48.8rem' }
                : { height: '41.8rem' }
            }
          >
            <button className={'close-calendar'} onClick={hideCalendar}>
              &times;
            </button>
            {showNightsLimit && (
              <div className="btns-nights-wrapper">
                <Tooltip
                  disableOutsideAlerter
                  className="content"
                  content={tooltipContent}
                >
                  <i
                    role="presentation"
                    aria-label="icon"
                    className="ic ic-info"
                  />
                </Tooltip>
                <label
                  className={`btn-nights ${duration === '7' ? 'active' : ''}`}
                >
                  7<span>Nights</span>
                  <input
                    className="btn-nights-input"
                    type="radio"
                    name="duration"
                    value="7"
                    checked={duration === '7'}
                    onChange={handleDuration}
                  />
                </label>
                <label
                  className={`btn-nights ${duration === '10' ? 'active' : ''}`}
                >
                  10
                  <span>Nights</span>
                  <input
                    className="btn-nights-input"
                    type="radio"
                    name="duration"
                    value="10"
                    checked={duration === '10'}
                    onChange={handleDuration}
                  />
                </label>
                <label
                  className={`btn-nights ${duration === '14' ? 'active' : ''}`}
                >
                  14
                  <span>Nights</span>
                  <input
                    className="btn-nights-input"
                    type="radio"
                    name="duration"
                    value="14"
                    checked={duration === '14'}
                    onChange={handleDuration}
                  />
                </label>
                <label
                  className={`btn-nights ${
                    duration === 'otherDuration' ? 'active' : ''
                  }`}
                >
                  Other Duration
                  <input
                    className="btn-nights-input"
                    type="radio"
                    name="duration"
                    value="otherDuration"
                    checked={duration === 'otherDuration'}
                    onChange={handleDuration}
                  />
                </label>
              </div>
            )}

            <DayPickerRangeController
              renderDayContents={renderDayContents}
              initialVisibleMonth={() =>
                calendarStartDate ? calendarStartDate : mMinDate
              }
              startDate={calendarStartDate} // required react-dates as bare minimum
              endDate={calendarEndDate} // required react-dates as bare minimum
              focusedInput={focusedInput} // required react-dates as bare minimum
              onFocusChange={handleFocusChange} // required react-dates as bare minimum
              onDatesChange={handleDatesChange} // required react-dates as bare minimum
              isOutsideRange={isOutsideRange}
              numberOfMonths={isMobile ? monthsToDisplay : monthsToDisplay}
              minimumNights={minimumNights}
              orientation={
                isMobile ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION
              }
              noBorder
              hideKeyboardShortcutsPanel
              withPortal={isMobile}
              onPrevMonthClick={onNavClick}
              onNextMonthClick={onNavClick}
              navPrev={
                prevVisible ? (
                  <i
                    role="presentation"
                    aria-label="icon"
                    className="ic-slick-left-arrow"
                  />
                ) : (
                  <div />
                )
              }
              navNext={
                nextVisible ? (
                  <i
                    role="presentation"
                    aria-label="icon"
                    className="ic-slick-right-arrow"
                  />
                ) : (
                  <div />
                )
              }
              transitionDuration={0}
              renderMonthElement={
                !isMobile ? renderMonthHeader : renderMonthHeader
              }
            />
            <div
              className={`calendar-bottom-bar row middle-xs ${
                showMaxNightsError || showMinNightsError
                  ? 'start-xs'
                  : 'between-xs'
              }`}
            >
              {calendarStartDate && calendarEndDate && (
                <button className="clear-dates" onClick={clearDates}>
                  Clear Dates
                </button>
              )}

              {calendarStartDate &&
                calendarEndDate &&
                !showMaxNightsError &&
                !showMinNightsError && (
                  <button className="apply-dates" onClick={handleApplyDates}>
                    Apply Dates
                  </button>
                )}
              {showMaxNightsError && (
                <span className="max-stay-error">
                  <i
                    role="presentation"
                    aria-label="icon"
                    className="ic-alert"
                  />{' '}
                  Maximum stay: 21 Nights
                </span>
              )}
              {showMinNightsError && (
                <span className="min-stay-error">
                  <i
                    role="presentation"
                    aria-label="icon"
                    className="ic-alert"
                  />{' '}
                  Minimum stay: 3 Nights
                </span>
              )}
            </div>
          </div>
        </CalendarWrapper>
      )}
    </div>
  );
};

export default Calendar;
