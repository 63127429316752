import React from 'react';

const viewAllOffersBtn = () => {
  return (
    <div className="row center-xs middle-xs qq-element-wrap">
      <a className="view-all-offers" href="/specials/">
        View All Offers
      </a>
    </div>
  );
};

export default viewAllOffersBtn;
