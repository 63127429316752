import React from 'react';

const PromotionalCode = props => {
  const { onChange, promocodeClassName = 'xs-6' } = props;

  return (
    <div className={`promocode ${promocodeClassName}`}>
      <label htmlFor="promocode">Promotional Code:</label>
      <input
        type="text"
        id="promocode"
        placeholder="Optional"
        name="promocode"
        className="qq-input promocode-input"
        onChange={onChange}
      />
    </div>
  );
};

export default PromotionalCode;
