import React, { useState } from 'react';
import getCookie from '~/js/modules/includes/get-cookie';
import VerticalQQ from './verticalQQ';
import HorizontalQQ from './horizontalQQ';

const QQ = props => {
  const {
    className = '',
    onChange,
    rstCode = '',
    horizontal = false,
    horizontalSpecial = false,
    withFlightsId = ['with-flights', 'with-flights-1'],
  } = props;

  const [vacationData, setVacationData] = useState({
    referral: getCookie('COBRAND_COOKIE'),
    agentId: getCookie('COBRAND_AGENT'),
    rstCode: rstCode,
    checkInDate: '',
    checkOutDate: '',
    rooms: '1',
    gateway: '',
    seatType: '',
    promocode: '',
    withFlightsVisible: false,
  });

  const alignment =
    !horizontal && !horizontalSpecial ? 'vertical' : 'horizontal';

  const saveVacationData = el => {
    const newVacationData = { ...vacationData, ...el };

    setVacationData(newVacationData);
    onChange(newVacationData);
  };

  return (
    <div className={`qq-wrapper ${alignment} ${className}`}>
      {!horizontal && !horizontalSpecial && (
        <VerticalQQ
          {...props}
          alignment={alignment}
          saveVacationData={saveVacationData}
          vacationData={vacationData}
          withFlightsId={withFlightsId}
        />
      )}
      {(horizontal || horizontalSpecial) && (
        <HorizontalQQ
          {...props}
          noTabs
          alignment={alignment}
          saveVacationData={saveVacationData}
          vacationData={vacationData}
          horizontalSpecial={horizontalSpecial}
          withFlightsId={withFlightsId}
        />
      )}
    </div>
  );
};

export default QQ;
