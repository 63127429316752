import React from 'react';

const WithFlightsElement = props => {
  const {
    value,
    onClick,
    // labelCopy = 'Hotel Only',
    id = 'with-flights',
  } = props;

  return (
    <div className="row center-xs top-xs middle-md with-flights-element">
      <div className="xs-12 no-gutters-md-min-up">
        <label
          className={`with-flights-element-label ${!value ? 'active' : ''}`}
        >
          <input
            type="radio"
            id={id + 'hotel'}
            name={id + 'bookingType'}
            value="hotelFlight"
            checked={!value}
            onChange={onClick}
          />
          Hotel + Flight
        </label>
        <label
          className={`with-flights-element-label ${value ? 'active' : ''}`}
        >
          <input
            type="radio"
            id={id}
            name={id + 'bookingType'}
            value="hotelOnly"
            checked={value}
            onChange={onClick}
          />
          Hotel Only
        </label>
        {/* <input
          type="checkbox"
          name={id}
          className="qq-checkbox with-flights"
          id={id}
          onChange={onClick}
          value={value}
          checked={value}
        />
        <label htmlFor={id}>{labelCopy}</label> */}
      </div>
    </div>
  );
};

export default WithFlightsElement;
