import getCookie from './includes/get-cookie';

const footer = () => {
  // const isDesktop = window.innerWidth > 992 ? true : false;

  // Display websites list
  const footerElem = document.getElementById('js-footer');
  const websitesToggle = document.getElementById('websites-toggle');
  const websitesList = document.getElementById('websites-list');

  // Current Environment
  const { env = '' } = window.sandals_app.env;

  function toggleWebsitesList() {
    websitesToggle.classList.toggle('active');
    websitesList.classList.toggle('visible');
  }

  websitesToggle.addEventListener('click', () => {
    toggleWebsitesList();
  });

  // Accessibility
  footerElem.addEventListener('keydown', e => {
    const listIsVisible = websitesList.classList.contains('visible');
    const toggleHasFocus = websitesToggle === document.activeElement;
    const listHasFocus = websitesList.contains(document.activeElement);

    if (
      (e.keyCode === 27 && listIsVisible && (listHasFocus || toggleHasFocus)) ||
      (listIsVisible && !toggleHasFocus && !listHasFocus)
    ) {
      toggleWebsitesList();
    }
  });

  // Display customer support modal
  const supportToggle = document.getElementById('support-toggle');
  const closeSupportToggle = document.getElementById('close-support-modal');

  function toggleSupportModal() {
    const supportModal = document.getElementById('support-modal');

    supportToggle.classList.toggle('active');
    supportModal.classList.toggle('visible');
  }

  // if (!isDesktop) {
  supportToggle.addEventListener('click', () => {
    toggleSupportModal();
  });

  closeSupportToggle.addEventListener('click', () => {
    toggleSupportModal();
  });
  // }

  // Add onClick to Your Privacy Choices link
  const aboutLinks = document.querySelectorAll(
    "[data-links='about-sandals'] ul li"
  );

  const isFromCalifornia =
    getCookie('isFromCalifornia') !== null &&
    getCookie('isFromCalifornia') === 'true';

  for (const linkItem of aboutLinks) {
    const linkItemText = linkItem.textContent.toLowerCase();

    if (
      linkItemText === 'do not sell or share my personal data' &&
      !isFromCalifornia
    ) {
      linkItem.classList.add('hide-item');
    }

    if (
      linkItemText === 'your privacy choices' ||
      (linkItemText === 'do not sell or share my personal data' &&
        isFromCalifornia)
    ) {
      const link = linkItem.firstElementChild.firstElementChild;

      if (env !== 'development' && env !== 'DEVELOPMENT') {
        link.addEventListener('click', e => {
          e.preventDefault();
          window.location.href = '/privacy-preferences/';
          // window.semaphore.push(['showPreferences']);
        });

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const showCookiePreferences = urlParams.get('showCookiePreferences');

        if (showCookiePreferences) {
          link.click();
        }
      }
    }
  }

  // PROMO POPUP
  const pathnames = [
    '/celebrations/',
    '/weddings/',
    '/weddings/inspirations/',
    '/weddings/venues/',
    '/weddings/packages/',
    '/weddings/planner/',
    '/celebrations/promotions/',
    '/weddings/inquiry/',
    '/weddings/real-weddings/courtney-matthew/',
    '/weddings/real-weddings/shantoura-nathan/',
    '/weddings/real-weddings/ale-clay/',
    '/weddings/packages/',
    '/weddings/planner/',
    '/celebrations/promotions/',
    '/curacao/weddings/',
    '/jamaica/weddings/',
    '/bahamas/weddings/',
    '/saint-lucia/weddings/',
    '/antigua/weddings/',
    '/grenada/weddings/',
    '/barbados/weddings/',
    '/weddings/real-weddings/',
    '/weddings/faq/',
    '/honeymoons/',
    '/proposals/',
    '/elopements/',
    '/anniversaries/',
    '/vow-renewals/',
  ];

  const getLocation = window.location.pathname;

  if (pathnames.includes(getLocation)) {
    const limitedPopupBtn = document.querySelector(
      '[data-micromodal-trigger="js-promo__modal"]'
    );

    function setNewsletterCookie() {
      const d = new Date();

      // 60sec*60mins*72hours = 259200 (3 day), 5*60*1000(5mins)
      d.setTime(d.getTime() + 259200000); // in milliseconds
      document.cookie = `HIDE_PROMO_POPUP=true;path=/;expires=${d.toGMTString()};`;
    }

    function timeOutPopup() {
      const hideLimitedPopup = getCookie('HIDE_PROMO_POPUP');

      if (!hideLimitedPopup) {
        limitedPopupBtn.click();
        setNewsletterCookie();
      }
    }

    if (limitedPopupBtn) {
      setTimeout(timeOutPopup, 20000);
    }

    document.addEventListener('mouseleave', e => {
      if (e.clientY < 10 && limitedPopupBtn) {
        timeOutPopup();
      }
    });
  }

  /* const limitedPopupBtn = document.querySelector(
    '[data-micromodal-trigger="js-promo__modal"]'
  );

  function setNewsletterCookie() {
    const d = new Date();

    // 60sec*60mins*72hours = 259200 (3 day), 5*60*1000(5mins)
    d.setTime(d.getTime() + 259200000); // in milliseconds
    document.cookie = `HIDE_PROMO_POPUP=true;path=/;expires=${d.toGMTString()};`;
  }

  function timeOutPopup() {
    const hideLimitedPopup = getCookie('HIDE_PROMO_POPUP');

    if (!hideLimitedPopup) {
      limitedPopupBtn.click();
      setNewsletterCookie();
    }
  }

  if (limitedPopupBtn) {
    setTimeout(timeOutPopup, 20000);
  }

  document.addEventListener('mouseleave', e => {
    if (e.clientY < 10 && limitedPopupBtn) {
      timeOutPopup();
    }
  });*/
};

const toggleActiveClassName = 'toggle__button--active';

const toggleContainerAll = document.querySelectorAll(
  '.js-toggle__container-footer'
);

toggleContainerAll.forEach(toggleContainer => {
  const toggleButton = toggleContainer.querySelector('.js-toggle__button');

  toggleButton.addEventListener('click', () => {
    for (let i = 0; i < toggleContainerAll.length; i++) {
      const actualButton =
        toggleContainerAll[i].querySelector('.js-toggle__button');
      const actualContent = toggleContainerAll[i].querySelector(
        '.js-toggle__content'
      );

      actualButton.classList.toggle(toggleActiveClassName);

      if (actualButton.classList.contains(toggleActiveClassName)) {
        actualContent.style.maxHeight = `${actualContent.scrollHeight + 30}px`;
      } else {
        actualContent.style.maxHeight = 0;
      }
    }
  });
});

export default footer;
