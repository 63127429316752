import moment from 'moment';
import PropTypes from 'prop-types';

export const START_DATE = 'startDate';
export const END_DATE = 'endDate';
export const HORIZONTAL_ORIENTATION = 'horizontal';
export const VERTICAL_ORIENTATION = 'vertical';
export const VERTICAL_SCROLLABLE = 'verticalScrollable';

export const ScrollableOrientationShape = PropTypes.oneOf([
  HORIZONTAL_ORIENTATION,
  VERTICAL_ORIENTATION,
  VERTICAL_SCROLLABLE,
]);

export function isBeforeDay(a, b) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) {
    return false;
  }

  const aYear = a.year();
  const aMonth = a.month();

  const bYear = b.year();
  const bMonth = b.month();

  const isSameYear = aYear === bYear;
  const isSameMonth = aMonth === bMonth;

  if (isSameYear && isSameMonth) {
    return a.date() < b.date();
  }

  if (isSameYear) {
    return aMonth < bMonth;
  }

  return aYear < bYear;
}

export function isInclusivelyAfterDay(a, b) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) {
    return false;
  }

  return !isBeforeDay(a, b);
}

const utils = {};

utils.compareObj = (obj1, obj2) => {
  // Loop through properties in object 1
  for (const p in obj1) {
    if (Object.hasOwnProperty.call(obj1, p)) {
      if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) {
        // console.log('Check property exists on both objects, will return false');
        return false;
      }

      switch (typeof obj1[p]) {
        // Deep compare objects
        case 'object':
          if (!utils.compareObj(obj1[p], obj2[p])) {
            // console.log(
            //   `key: ${p} deep compare objects, case object, will return false`
            // );
            return false;
          }
          break;
        // Compare function code
        case 'function':
          if (
            typeof obj2[p] === 'undefined' ||
            (p !== 'compare' && obj1[p].toString() !== obj2[p].toString())
          ) {
            // console.log(
            //   'compare function code, case function, will return false'
            // );
            return false;
          }
          break;
        // Compare values
        default:
          if (obj1[p] !== obj2[p]) {
            // console.log(
            //   `compare values, key: ${p}, default if ${obj1[p]} !== ${obj2[p]} will return false`
            // );
            return false;
          }
      }
    }
  }

  // Check object 2 for any extra properties
  for (const p in obj2) {
    if (typeof obj1[p] === 'undefined') {
      return false;
    }
  }

  return true;
};

export const compareObj = utils.compareObj;
