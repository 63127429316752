const keyboardFocus = () => {
  function handleOnTab(e) {
    function handleOnMouse() {
      document.body.classList.remove('focus--enabled');

      window.removeEventListener('mousedown', handleOnMouse);
      window.addEventListener('keydown', handleOnTab);
    }

    if (e.keyCode === 9) {
      document.body.classList.add('focus--enabled');

      window.removeEventListener('keydown', handleOnTab);
      window.addEventListener('mousedown', handleOnMouse);
    }
  }

  window.addEventListener('keydown', handleOnTab);
};

export default keyboardFocus;
