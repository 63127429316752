const searchBar = () => {
  const headerEl = document.getElementById('js-header');
  const searchBarEl = headerEl.querySelector('.search__bar');
  const searchBtn = headerEl.querySelector('.search__button');
  const redirectUrl = '/search/?searchTerm=';

  function encodeInput(input) {
    const encoded = document.createElement('div');

    encoded.innerText = input;

    return encoded.innerHTML;
  }

  const redirectToSearch = () => {
    const searchTerm = encodeInput(encodeURI(searchBarEl.value));

    if (searchTerm.length > 0) {
      window.location.href = `${redirectUrl}${btoa(searchTerm)}`;
    }
  };

  searchBarEl.addEventListener('keyup', e => {
    if (e.keyCode === 13) {
      redirectToSearch();
    }
  });

  searchBtn.addEventListener('click', () => redirectToSearch());
};

export default searchBar;
