import React from 'react';
import m from 'moment';

export const ResortHeader = ({
  topText = '',
  smallText = '',
  blueBannerText = '',
  date = '',
  format = 'MMMM D, YYYY',
  isSCR = '',
}) => {
  const mDate = m(date, 'MM/DD/YYYY', true);

  return (
    <div className="row center-xs middle-xs qq-element-wrap">
      <div className="xs-12">
        {(topText || smallText) && (
          <div className="row center-xs middle-xs qq__resort-header">
            <div className="xs-12">
              <p>{topText}</p>
              <small>{smallText}</small>
            </div>
          </div>
        )}
        {isSCR ? (
          <div className="row center-xs middle-xs qq__blue-banner__scr">
            <div className="xs-12">
              <span
                dangerouslySetInnerHTML={{
                  __html: blueBannerText,
                }}
              />
            </div>
          </div>
        ) : (
          <div className="row center-xs middle-xs qq__blue-banner">
            <div className="xs-12">
              <span
                dangerouslySetInnerHTML={{
                  __html: blueBannerText,
                }}
              />
            </div>
          </div>
        )}
        {date && (
          <div className="row center-xs middle-xs qq__resort-header__date">
            <time dateTime={mDate.toDate()}>{mDate.format(format)}</time>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResortHeader;
