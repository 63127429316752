import React, { useState } from 'react';

const SelectNumberRooms = props => {
  const { onChange, roomsClassName = 'xs-3', roomsId = '' } = props;
  const [selectedRooms, setSelectedRooms] = useState('1');

  const handleOptionRooms = e => {
    const rooms = e.target.value;

    onChange(rooms);
    setSelectedRooms(rooms);
  };

  return (
    <div className={`rooms-check ${roomsClassName}`}>
      <label>Rooms:</label>
      <div className="rooms-check-wrap">
        <label
          className={`qq-input rooms-check-label ${
            selectedRooms === '1' ? 'active' : ''
          }`}
          htmlFor={`${roomsId}-roomgroups-1`}
        >
          <input
            className="rooms-check-input"
            type="radio"
            name={`${roomsId}-roomgroups`}
            id={`${roomsId}-roomgroups-1`}
            value="1"
            checked={selectedRooms === '1'}
            onChange={handleOptionRooms}
          />
          1
        </label>
        <label
          className={`qq-input rooms-check-label ${
            selectedRooms === '2' ? 'active' : ''
          }`}
          htmlFor={`${roomsId}-roomgroups-2`}
        >
          <input
            className="rooms-check-input"
            type="radio"
            name={`${roomsId}-roomgroups`}
            id={`${roomsId}-roomgroups-2`}
            value="2"
            checked={selectedRooms === '2'}
            onChange={handleOptionRooms}
          />
          2
        </label>
      </div>
      <small className="rooms-check-criteria">2 adults per room</small>
    </div>
  );
};

export default SelectNumberRooms;
