import React, { useState, useEffect } from 'react';

const RedBanner = props => {
  const {
    redBanner,
    hasSpecials,
    handleSpecialsRedirect,
    specialsVisible,
    carouselItemId,
    redBannerBackground,
    collapsibleBanner,
  } = props;
  const handleClick = () => {
    handleSpecialsRedirect();
  };
  const isVisible = i =>
    i === carouselItemId ? 'qq-element-visible' : 'qq-element-hidden';

  const [visibleId, setVisibleId] = useState(0);
  const [overrideClass, setOverrideClass] = useState('');

  useEffect(() => {
    setVisibleId(carouselItemId);

    const overrideClassName = carouselItemId > 0 ? 'override-red' : '';

    setOverrideClass(overrideClassName);
  });

  let redBannerClassName;

  if (collapsibleBanner) {
    redBannerClassName = redBannerBackground ? '' : 'collapsed-red-banner';
  } else {
    redBannerClassName = '';
  }

  return (
    <div className="row center-xs middle-xs qq-element-wrap">
      <div className="xs-12">
        <div
          className={`row center-xs middle-xs red-banner ${overrideClass} ${redBannerClassName}`}
        >
          <div onClick={handleClick} className="cursor-pointer">
            {redBanner.map((banner, i) => {
              return (
                <div
                  key={i}
                  className={`redbanner-element qq-element-transition ${isVisible(
                    i
                  )} ${visibleId}`}
                >
                  {banner}
                </div>
              );
            })}
            {hasSpecials && !specialsVisible && (
              <div className="arrow-up">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="22.134"
                  height="22.134"
                  viewBox="0 0 1080 1080"
                  xmlSpace="preserve"
                >
                  <desc>Created with Fabric.js 5.2.4</desc>
                  <defs />
                  <g
                    transform="matrix(1 0 0 1 540 540)"
                    id="a93c2050-4a43-413a-b6f2-643cad439b06"
                  >
                    <rect
                      style={{
                        stroke: 'none',
                        strokeWidth: '1',
                        strokeDasharray: 'none',
                        strokeLinecap: 'butt',
                        strokeDashoffset: '0',
                        strokeLinejoin: 'miter',
                        strokeMiterlimit: '4',
                        fill: 'rgb(255,255,255)',
                        fillRule: 'nonzero',
                        opacity: '1',
                        visibility: 'hidden',
                      }}
                      vectorEffect="non-scaling-stroke"
                      x="-540"
                      y="-540"
                      rx="0"
                      ry="0"
                      width="1080"
                      height="1080"
                    />
                  </g>
                  <g
                    transform="matrix(1 0 0 1 540 540)"
                    id="f6677a63-bca5-4d59-96d1-158c24e64d34"
                  />
                  <g transform="matrix(48.79 0 0 48.79 540 540)" id="Path_6">
                    <path
                      style={{
                        stroke: 'none',
                        strokeWidth: '1',
                        strokeDasharray: 'none',
                        strokeLinecap: 'butt',
                        strokeDashoffset: '0',
                        strokeLinejoin: 'miter',
                        strokeMiterlimit: '4',
                        fill: 'rgb(0,0,0)',
                        fillRule: 'nonzero',
                        opacity: '1',
                      }}
                      vectorEffect="non-scaling-stroke"
                      transform=" translate(-385.76, -460.53)"
                      d="M 385.763 449.466 C 379.6508646736226 449.466 374.69599999999997 454.42086467362265 374.69599999999997 460.533 C 374.69599999999997 466.6451353263774 379.6508646736226 471.6 385.763 471.6 C 391.87513532637735 471.6 396.83 466.6451353263774 396.83 460.533 C 396.82283640150166 454.42383439358554 391.87216560641446 449.47316359849833 385.763 449.466 Z M 385.763 470.125 C 380.46603696437285 470.125 376.17199999999997 465.8309630356271 376.17199999999997 460.534 C 376.17199999999997 455.23703696437286 380.46603696437285 450.943 385.763 450.943 C 391.0599630356271 450.943 395.354 455.23703696437286 395.354 460.534 C 395.3490383476719 465.8289064901716 391.0579064901716 470.12003834767194 385.763 470.125 Z"
                      strokeLinecap="round"
                    />
                  </g>
                  <g
                    transform="matrix(0 48.79 -48.79 0 540.05 527.24)"
                    id="Path_5"
                  >
                    <path
                      style={{
                        stroke: 'none',
                        strokeWidth: '1',
                        strokeDasharray: 'none',
                        strokeLinecap: 'butt',
                        strokeDashoffset: '0',
                        strokeLinejoin: 'miter',
                        strokeMiterlimit: '4',
                        fill: 'rgb(0,0,0)',
                        fillRule: 'nonzero',
                        opacity: '1',
                      }}
                      vectorEffect="non-scaling-stroke"
                      transform=" translate(-388.04, -462.56)"
                      d="M 388.044 460.028 L 383.393 464.507 L 383.972 465.101 L 388.044 461.187 L 392.116 465.101 L 392.695 464.507 Z"
                      strokeLinecap="round"
                    />
                  </g>
                </svg>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedBanner;
