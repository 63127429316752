import React from 'react';

const topBanner = props => {
  const { children } = props;

  return (
    <div className="row xs-center xs-middle">
      <div className="xs-12">
        <div className="top-banner">{children}</div>
      </div>
    </div>
  );
};

export default topBanner;
