const aitacodeConverter = rstCode => {
  switch (rstCode) {
    case 'SAT':
      return { iata: 'ANU', value: '37954' };
    case 'SRB':
      return { iata: 'NAS', value: '37926' };
    case 'SEB':
      return { iata: 'GGT', value: '37947' };
    case 'SBD':
      return { iata: 'BGI', value: '66937' };
    case 'SBR':
      return { iata: 'BGI', value: '75244' };
    case 'SCR':
      return { iata: 'CUR', value: '84841' };
    case 'SLS':
      return { iata: 'GND', value: '64572' };
    case 'SGO':
      return { iata: 'MBJ,KIN', value: '37928' };
    case 'BRP':
      return { iata: 'MBJ,KIN', value: '37963' };
    case 'SMB':
      return { iata: 'MBJ,KIN', value: '37950' };
    case 'SRC':
      return { iata: 'MBJ,KIN', value: '37949' };
    case 'INN':
      return { iata: 'MBJ,KIN', value: '37953' };
    case 'SNG':
      return { iata: 'MBJ,KIN', value: '37951' };
    case 'SWH':
      return { iata: 'MBJ,KIN', value: '37948' };
    case 'SDR':
      return { iata: 'MBJ,KIN', value: '96677' };
    case 'SHC':
      return { iata: 'UVF', value: '37956' };
    case 'SLU':
      return { iata: 'UVF', value: '37955' };
    case 'SGL':
      return { iata: 'UVF', value: '37957' };
    case 'SSV':
      return { iata: 'SVD', value: '99980' };
    default:
      return '';
  }
};

export default aitacodeConverter;
