import React from 'react';

const WithFlightsElement = props => {
  const {
    value,
    onClick,
    WithFlightsElementClassName,
    // labelCopy = 'Hotel Only',
    id = 'with-flights',
  } = props;

  return (
    <div
      className={`${WithFlightsElementClassName} sm-5 xl-3 with-flights-element`}
    >
      <label className={`with-flights-element-label ${!value ? 'active' : ''}`}>
        <input
          type="radio"
          id={id + 'hotel'}
          name={id + 'bookingType'}
          value="hotelFlight"
          checked={!value}
          onChange={onClick}
        />
        Hotel + Flight
      </label>
      <label className={`with-flights-element-label ${value ? 'active' : ''}`}>
        <input
          type="radio"
          id={id}
          name={id + 'bookingType'}
          value="hotelOnly"
          checked={value}
          onChange={onClick}
        />
        Hotel Only
      </label>
    </div>
  );
};

export default WithFlightsElement;
