const _blank = () => {
  const links = document.querySelectorAll('body a');

  if (links) {
    for (let i = 0; i < links.length; i++) {
      const href = links[i].getAttribute('href');

      if (href && href.includes('?_blank')) {
        const newHref = links[i].getAttribute('href').replace('?_blank', '');

        links[i].href = newHref;
        links[i].target = '_blank';
        links[i].rel = 'noopener noreferrer';
      }
    }
  }
};

export default _blank;
