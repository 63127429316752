import React, { useRef, useEffect } from 'react';

const OutsideAlerter = props => {
  const { handler } = props;
  const wrapperRef = useRef(null);

  useEffect(() => {
    /**
     * Close if clicked on outside of element
     */
    function handleClickOutside(event) {
      const target = event.target;

      if (wrapperRef.current && !wrapperRef.current.contains(target)) {
        handler();
      }
    }

    // Bind the event listener
    document.addEventListener('click', handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside);
    };
  }, [wrapperRef]);

  return <div ref={wrapperRef}>{props.children}</div>;
};

export default OutsideAlerter;
