import React from 'react';

const SearchCriteriaFlights = props => {
  const {
    onChange,
    value,
    searchCriteriaClassName = 'xs-12',
    isShowSuntitle = true,
  } = props;

  return (
    <div className={searchCriteriaClassName}>
      <label htmlFor="search-criteria">Flight Class:</label>
      <div className="qq-input-wrapper">
        <div className="select-wrapper custom-select">
          <select
            name="search-criteria"
            id="search-criteria"
            className="qq-input search-criteria"
            value={value}
            onChange={onChange}
          >
            <option value="">Any</option>
            <option value="Y">Economy</option>
            <option value="W">Premium Economy</option>
            <option value="C">Business</option>
            <option value="F">First</option>
          </select>
        </div>
      </div>
      {isShowSuntitle && (
        <small className="small-search-criteria">
          Results will be displayed on the "Flights" section
        </small>
      )}
    </div>
  );
};

export default SearchCriteriaFlights;
