import { useEffect } from 'react';

const useScrollIntoView = (
  ref,
  dependency,
  currentPosition = false,
  additionalOffset = 100
) => {
  const header = document.querySelector('.header_default');
  const stickyNav = document.querySelector('.sticky-nav');

  let position = 0;
  const windowScrollY = currentPosition ? window.scrollY : 0;

  const checkElementHeight = elementOnTop => {
    if (elementOnTop) {
      position += elementOnTop.clientHeight;
    }
  };

  [header, stickyNav].forEach(element => checkElementHeight(element));

  useEffect(() => {
    if (ref.current !== null && dependency) {
      const target = ref.current;

      // const additionalOffset = 100;

      document.querySelector('html, body').scrollTo({
        top:
          target.getBoundingClientRect().top -
          position -
          additionalOffset +
          windowScrollY,

        behavior: 'smooth',
      });
    }
  }, [dependency]);
};

export default useScrollIntoView;
