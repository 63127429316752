import _ from './modules/utils';
import header from '~/js/modules/header';
import footer from '~/js/modules/footer';
import microModal from '~/js/modules/includes/micro-modal';
import _blank from '~/js/modules/includes/_blank-util';
import smoothScroll from '~/js/modules/includes/smooth-scroll';
import keyboardFocus from '~/js/modules/includes/keyboard-focus';

const headerReady = !!document.getElementById('js-header');
const footerReady = !!document.getElementById('js-footer');
const modalsReady = !!document.querySelector('.js-micromodal');

Object.assign(window, { _ });

window.onpageshow = event => {
  if (event.persisted) {
    window.location.reload();
  }
};

window.onload = () => {
  smoothScroll();
};

headerReady && header();
footerReady && footer();
modalsReady && microModal();

keyboardFocus();
_blank();
() => resolve;
