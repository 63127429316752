import React from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import formActions from '~/js/components/global/form/utils/formActions';

const { addValidClass } = formActions;

function ReCAPTCHAv2({
  name,
  handler,
  touched,
  error,
  unwrapped,
  innerRef,
  accountsSitekey,
}) {
  const sitekey = window.sandals_app.googleRecaptchaSite;

  return unwrapped ? (
    <>
      <ReCAPTCHA
        aria-label={name}
        aria-describedby={`${name}-help`}
        ref={innerRef}
        sitekey={accountsSitekey ? accountsSitekey : sitekey}
        onChange={captchaRsp => handler(name, captchaRsp)}
        onExpired={() => console.log('token expired')}
      />
      {touched && error ? <span className="san-feedback">{error}</span> : null}
    </>
  ) : (
    <div
      className={`row center-xs middle-xs san-form-group ${
        touched ? addValidClass(!error) : ''
      }`}
    >
      <div className="xs-10 sm-6">
        <ReCAPTCHA
          style={{ display: 'flex', justifyContent: 'center' }}
          aria-label={name}
          ref={innerRef}
          aria-describedby={`${name}-help`}
          sitekey={accountsSitekey ? accountsSitekey : sitekey}
          onChange={captchaRsp => handler(name, captchaRsp)}
          onExpired={() => console.log('token expired')}
        />
        {touched && error ? (
          <span className="san-feedback">{error}</span>
        ) : null}
      </div>
    </div>
  );
}

ReCAPTCHAv2.propTypes = {
  name: PropTypes.string,
  handler: PropTypes.func,
  touched: PropTypes.bool,
  error: PropTypes.string,
};

ReCAPTCHAv2.defaultProps = {
  name: 'gcaptcha',
  touched: false,
  error: '',
};

export default ReCAPTCHAv2;
