import React, { useEffect, useState } from 'react';
import m from 'moment';
import BottomElement from './bottomElement';
import globalConfig from '../../../modules/global';
// import { dateFormatter, obeFormat } from '../../resorts/rooms/utils/dateUtils';
import aitacodeConverter from './aitacodeConverter';

const MainButton = props => {
  const { DEFAULTS } = globalConfig;
  const { obeURL } = DEFAULTS;
  const [isDisabled, setIsDisabled] = useState(true);

  const {
    copy,
    bottomElement,
    vacationData,
    horizontal = false,
    buttonAction,
    targetBlank,
    customCallback,
    isCallendarUk,
    additionalClassName,
  } = props;

  const actionHandler = url => {
    if (targetBlank) {
      window.open(url);
    } else {
      window.location.href = url;
    }
  };

  useEffect(() => {
    if (isCallendarUk === false) {
      setIsDisabled(false);
    } else if (!vacationData?.withFlightsVisible) {
      setIsDisabled(
        !(
          vacationData.rstCode &&
          vacationData.checkInDate &&
          vacationData.gateway
        )
      );
    } else {
      setIsDisabled(!(vacationData.rstCode && vacationData.checkInDate));
    }
  }, [vacationData]);

  const clickHandler = () => {
    if (!customCallback) {
      const {
        // referral,
        rooms,
        // agentId,
        rstCode,
        checkInDate,
        checkOutDate,
        gateway,
        seatType,
        promocode,
        withFlightsVisible,
      } = vacationData;

      const converterDate = date => {
        const res = m(date, 'YYYY-MM-DD', true).isValid()
          ? m(date, 'YYYY-MM-DD', true)
          : m(date, 'MM-DD-YYYY', true);

        return res;
      };

      const obeCheckIn = converterDate(checkInDate).format('YYYY-MM-DD');
      const obeCheckOut = converterDate(checkOutDate).format('YYYY-MM-DD');

      const days = converterDate(checkOutDate).diff(
        converterDate(checkInDate),
        'days'
      );
      const { iata, value } = aitacodeConverter(rstCode);

      const params = `?resultkey=random&searchtype=${
        !withFlightsVisible ? 'flighthotel' : 'hotel'
      }&sid=30094&flight-sid=30073&hotel-sid=30073&product=${
        !withFlightsVisible ? 'multisearch' : 'hotel'
      }${
        !withFlightsVisible ? '&products=hotel&products=flight' : ''
      }&currency=GBP&redirecturl=${
        !withFlightsVisible ? '%2Fresorts' : '%2Fresort'
      }&hotel-redirecturl=%2Fresorts&flight-redirecturl=%2Fflight-results&returnurl=%2Fsearch-form-example&returnurl=%2F&redirectpageid=9841&depdate=${obeCheckIn}&startdate=${obeCheckIn}&enddate=${obeCheckOut}&depdate-day=${m(
        obeCheckIn
      ).format('DD')}&depdate-month=${m(obeCheckIn).format(
        'MM'
      )}&depdate-year=${m(obeCheckIn).format('YYYY')}&startdate-day=${m(
        obeCheckIn
      ).format('DD')}&startdate-month=${m(obeCheckIn).format(
        'MM'
      )}&startdate-year=${m(obeCheckIn).format('YYYY')}&enddate-day=${m(
        obeCheckOut
      ).format('DD')}&enddate-month=${m(obeCheckOut).format(
        'MM'
      )}&enddate-year=${m(obeCheckOut).format('YYYY')}&locationid=1&depair=${
        !withFlightsVisible ? gateway : ''
      }&originiata=${gateway}&destinationiata=${iata}&destinationiata2=&destinationiata3=&destinationcode=1&destair=${iata}&destair2=&destair3=&nights=${days}&roomcount=${rooms}&adults=${
        Number(rooms) * 2
      }&adults-1=2&adults-2=2&adults-3=2&adults-4=2&children-1=0&children-2=0&children-3=0&children-4=0&infants-1=0&infants-2=0&infants-3=0&infants-4=0&multisearch=${
        !withFlightsVisible ? '1' : '0'
      }&multiflightcount=3&flights=1&journeyoption=return&depart=${
        !withFlightsVisible ? gateway : ''
      }&hotelcode=${value}&flightclass=${seatType}&promocode=${promocode}&roomgroups=${rooms}`;

      const url = buttonAction ? buttonAction : `${obeURL}${params}`;

      if (typeof buttonAction === 'function') {
        url = buttonAction();
      }

      actionHandler(url);
    } else {
      customCallback();
    }
  };

  const size = horizontal ? 'xs-12 sm-11' : 'xs-12';

  return (
    <>
      <div
        className={`row center-xs middle-xs qq-main-btn ${additionalClassName}`}
      >
        <button
          disabled={isDisabled}
          className={`${size}`}
          onClick={clickHandler}
        >
          {copy}
        </button>
      </div>
      {bottomElement && <BottomElement>{bottomElement}</BottomElement>}
    </>
  );
};

export default MainButton;
