import React from 'react';
import globalConfig from '../../../modules/global';
import m from 'moment';

const ResortOptions = props => {
  const { region, onClick: setValue } = props;
  const { country, resorts } = region;
  const { name } = country;

  const resortNameNoBrand = rstName => rstName.substr(rstName.indexOf(' ') + 1);
  const countryNameMod = cName => {
    let returnName =
      name.toLowerCase() === 'antigua and barbuda'
        ? cName.split(' ')[0]
        : cName;

    if (name.toLowerCase() === 'saint vincent and the grenadines') {
      returnName = 'Saint Vincent';
    }

    return returnName;
  };

  const { DEFAULTS } = globalConfig;
  const startDate = sessionStorage.getItem('startDate');

  const convertedCheckInDate = m(startDate);

  const enableSSV =
    startDate !== null
      ? convertedCheckInDate.isSameOrAfter(DEFAULTS.minDateSSV)
      : true;

  return (
    <li className={`opt-region-group ${countryNameMod(name).toLowerCase()}`}>
      <ul>
        <li className="opt-group">{countryNameMod(name)}</li>
        {resorts.map((resort, i) => (
          <li
            key={i}
            className={`opt-item ${
              !enableSSV && resort.code === 'SSV' ? 'disabled' : ''
            }`}
            data-value={resort.code}
            data-label={resort.shortName}
            onClick={setValue}
          >
            <small>Sandals</small>{' '}
            <span>{resortNameNoBrand(resort.shortName)}</span>
            {resort.code === 'SSV' ? (
              <div className="opt-item opening">
                <br />
                <span>Now Open</span>
              </div>
            ) : (
              ''
            )}
          </li>
        ))}
      </ul>
    </li>
  );
};

export default ResortOptions;
