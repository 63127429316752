import React from 'react';

import SpecificDatesHorizontalRegularV2 from './specificDates/indexV2';
import SpecificDatesHorizontalSpecialV2 from './specificDates/specialV2';

const SpecificDatesHorizontal = props => {
  const { horizontalSpecial } = props;

  return (
    <>
      {!horizontalSpecial && <SpecificDatesHorizontalRegularV2 {...props} />}
      {horizontalSpecial && <SpecificDatesHorizontalSpecialV2 {...props} />}
    </>
  );
};

export default SpecificDatesHorizontal;
